@charset "UTF-8";
/*
*   Template name: Hotash - React Admin Dashboard Template
*   Themeforest portfolio: (https://themeforest.net/user/mironcoder/portfolio)
*   Service center: (https://www.fiverr.com/mironcoder)
*   Support email: (mironcoder@gmail.com)
*   All copyrights reserved by Mironcoder
*/
@import "rsuite/dist/rsuite.css";
.light_mode {
  --primaryHEX: #0858f7;
  --primaryGreen: #55DFB2;
  --lightColor: #ffffff;
  --textColor: #5e5d72;
  --activeColor: #0858f7;
  --placeholderColor: #9a9a9a;
  --headingColor: #403e57;
  --colorSetting: #000;
  --bodyBG: #ffffff;
  --cardBG: #ffffff;
  --paperBG: #ffffff;
  --lightBG: #ffffff;
  --thinBorder: #f8f8f8;
  --lightBorder: #f0f0f0;
  --deepBorder: #e4e4e4;
  --hardBorder: #d1d1d1;
  --thinGray: #f8f8f8;
  --lightGray: #f0f0f0;
  --grayDropdown: #a2a2a2;
  --deepGray: #e4e4e4;
  --hardGray: #d1d1d1;
  --hoverBG: #f1f1f1;
  --activeBG: #ebf6ff;
  --facebook: #3b5998;
  --linkedin: #0e76a8;
  --twitter: #00acee;
  --google: #E60023;
  --apple: #555555;
  --instagram: #F77737;
  --whatsapp: #25D366;
  --pinterest: #f0002a;
  --youtube: #FF0000;
  --primaryRGB: 8, 88, 247;
  --lightRGB: 255, 255, 255;
  --blackRGB: 0, 0, 0;
  --redRGB: 241, 17, 51;
  --darkRGB: 1, 12, 44;
  --grayRGB: 129, 129, 129;
  --blueRGB: 43, 119, 229;
  --greenRGB: 26, 159, 83;
  --purpleRGB: 190, 14, 225;
  --orangeRGB: 255, 115, 0;
  --yellowRGB: 225, 148, 14;
  --btnBoxShadow: 0px 8px 18px 0px rgba(var(--blackRGB), 12%);
  --cardBoxShadow: 0px 15px 25px 0px rgba(var(--blackRGB), 6%);
  --paperBoxShadow: 0px 15px 35px 0px rgba(var(--blackRGB), 10%);
}

.dark_mode {
  --primaryHEX: #1e66ff;
  --primaryGreen: #27CCCA;
  --lightColor: #ffffff;
  --textColor: #b4b4b4;
  --activeColor: #94b7ff;
  --placeholderColor: #acacac;
  --headingColor: #e4e4e4;
  --colorSetting: #fff;
  --bodyBG: #112143;
  --cardBG: #112143;
  --paperBG: #132140;
  --grayDropdown: #071739;
  --lightBG: #071739;
  --thinBorder: #253557;
  --lightBorder: #1b2b4d;
  --deepBorder: #253557;
  --hardBorder: #31436a;
  --thinGray: #071739;
  --lightGray: #1b2b4d;
  --deepGray: #00031f;
  --hardGray: #000015;
  --hoverBG: #253557;
  --activeBG: rgba(40, 129, 227, 0.100);
  --facebook: #3b5998;
  --linkedin: #0e76a8;
  --twitter: #00acee;
  --google: #E60023;
  --instagram: #F77737;
  --whatsapp: #25D366;
  --pinterest: #f0002a;
  --youtube: #FF0000;
  --apple: #555555;
  --primaryRGB: 35, 108, 255;
  --lightRGB: 17, 33, 67;
  --blackRGB: 0, 0, 0;
  --redRGB: 241, 17, 51;
  --darkRGB: 1, 12, 44;
  --grayRGB: 129, 129, 129;
  --blueRGB: 43, 119, 229;
  --greenRGB: 26, 159, 83;
  --purpleRGB: 190, 14, 225;
  --orangeRGB: 255, 115, 0;
  --yellowRGB: 225, 148, 14;
  --btnBoxShadow: 0px 8px 18px 0px rgba(var(--blackRGB), 12%);
  --cardBoxShadow: 0px 15px 25px 0px rgba(var(--blackRGB), 6%);
  --paperBoxShadow: 0px 15px 35px 0px rgba(var(--blackRGB), 10%);
}

* {
  margin: 0px;
  padding: 0px;
  outline: 0px;
  box-sizing: border-box;
  border: none;
}

img {
  vertical-align: middle;
}

a {
  text-decoration: none;
  display: inline-block;
  color: var(--textColor);
}
a:hover {
  text-decoration: none;
  color: inherit;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

sup,
sub {
  top: inherit;
  bottom: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
  color: var(--headingColor);
}

p {
  margin: 0px;
}

html,
body {
  direction: ltr;
  scroll-behavior: smooth;
  color: var(--textColor);
  background-color: var(--bodyBG);
  padding: 0;
  margin: 0;
}

input,
select,
button,
textarea {
  border: none;
  outline: none;
  background: none;
  color: var(--textColor);
}
input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: none;
}
input::placeholder,
select::placeholder,
button::placeholder,
textarea::placeholder {
  color: var(--placeholderColor);
}

button {
  cursor: pointer;
  line-height: 0px;
  color: var(--textColor);
}

body::-webkit-scrollbar-track {
  background: red;
}

body {
  font-size: 16px;
  font-family: "Gilroy", sans-serif;
}

input,
textarea {
  font-size: 15px;
}
input::placeholder,
textarea::placeholder {
  font-size: 14px;
  font-weight: 400;
}

h1 {
  font-size: 36px;
  font-weight: 700;
}

h2 {
  font-size: 28px;
  font-weight: 700;
}

h3 {
  font-size: 22px;
  font-weight: 700;
}

h4 {
  font-size: 20px;
  font-weight: 600;
}

h5 {
  font-size: 18px;
  font-weight: 500;
}

h6 {
  font-size: 16px;
  font-weight: 500;
}

p {
  line-height: 1.8;
}

.alert {
  font-size: 15px;
  padding: 15px 20px;
  border-radius: 8px;
}

.btn-close {
  padding: 0px;
  background-size: 12px;
}

.alert-dismissible .btn-close {
  padding: 12px;
}

.mc-alert {
  padding: 18px 20px 20px;
  margin-bottom: 30px;
  border-radius: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 15px;
}
.mc-alert.red {
  color: #a70d24;
  background-color: #ffc4cd;
}
.mc-alert.red i {
  background-color: #ff9baa;
}
.mc-alert.red h3 {
  color: #a70d24;
}
.mc-alert.red button {
  color: #a70d24;
  background-color: #ffc4cd;
}
.mc-alert.blue {
  color: #2351af;
  background-color: #c1e1fc;
}
.mc-alert.blue i {
  background-color: #96cefa;
}
.mc-alert.blue h3 {
  color: #2351af;
}
.mc-alert.blue button {
  color: #2351af;
  background-color: #c1e1fc;
}
.mc-alert.gray {
  color: #5a5a5a;
  background-color: #e4e4e4;
}
.mc-alert.gray i {
  background-color: #d1d1d1;
}
.mc-alert.gray h3 {
  color: #5a5a5a;
}
.mc-alert.gray button {
  color: #5a5a5a;
  background-color: #e4e4e4;
}
.mc-alert.green {
  color: #18633a;
  background-color: #bdf5d3;
}
.mc-alert.green i {
  background-color: #89ecb3;
}
.mc-alert.green h3 {
  color: #18633a;
}
.mc-alert.green button {
  color: #18633a;
  background-color: #bdf5d3;
}
.mc-alert.purple {
  color: #8c099f;
  background-color: #f6caff;
}
.mc-alert.purple i {
  background-color: #f3a0ff;
}
.mc-alert.purple h3 {
  color: #8c099f;
}
.mc-alert.purple button {
  color: #8c099f;
  background-color: #f6caff;
}
.mc-alert.orange {
  color: #a1400b;
  background-color: #ffe1a5;
}
.mc-alert.orange i {
  background-color: #ffca6d;
}
.mc-alert.orange h3 {
  color: #a1400b;
}
.mc-alert.orange button {
  color: #a1400b;
  background-color: #ffe1a5;
}
.mc-alert i {
  font-size: 22px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 10px;
  text-align: center;
  text-shadow: 2px 4px 6px rgba(var(--blackRGB), 10%);
  flex-shrink: 0;
}
.mc-alert h3 {
  flex: auto;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
}
.mc-alert h3 span {
  display: block;
  margin-top: 6px;
  text-transform: lowercase;
  font-size: 15px;
  font-weight: 400;
}
.mc-alert button {
  position: absolute;
  top: -15px;
  right: 20px;
  z-index: 1;
  width: 35px;
  height: 35px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  border: 3px solid var(--lightColor);
}

.dark_mode .mc-alert button {
  border-color: var(--lightBorder);
}

.mc-logo img {
  height: 45px;
}

.mc-logo-group {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px 2px;
}
.mc-logo-group img {
  height: 45px;
}
.mc-logo-group span {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--headingColor);
}
.mc-logo-group.lg img {
  height: 60px;
}
.mc-logo-group.lg span {
  font-size: 34px;
}

.mc-tabs {
  border: none;
  margin-bottom: 50px;
}
@media (max-width: 575px) {
  .mc-tabs {
    justify-content: center;
    gap: 5px;
  }
}
.mc-tabs .nav-link {
  border: none;
  padding: 20px 25px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  color: var(--headingColor);
  text-transform: capitalize;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-tabs .nav-link:hover {
  color: var(--lightColor);
  background-color: var(--primaryHEX);
}
.mc-tabs .nav-link.active {
  border: none;
  color: var(--lightColor);
  background-color: var(--primaryHEX);
}

.mc-tabpane {
  padding: 0px 30px 30px;
}
@media (max-width: 575px) {
  .mc-tabpane {
    padding: 0px;
  }
}
.mc-tabpane .mc-icon-field {
  margin-bottom: 0px;
  border-color: var(--deepBorder);
  background-color: var(--thinGray);
}
.mc-tabpane .mc-icon-field:focus-within {
  border-color: var(--primaryHEX);
}
.mc-tabpane.settings .form-check {
  margin-bottom: 15px;
}

.form-group.inline {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px;
}
.form-group.inline .form-label {
  width: 70px;
  flex-shrink: 0;
  margin-bottom: 0px;
}

.form-label {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  color: var(--headingColor);
}

.form-select,
.form-control {
  width: 100%;
  /* height: 45px; */
  padding: 0px 16px;
  border-radius: 8px;
  border: 1px solid var(--deepBorder);
  background-color: var(--lightGray);
  font-size: 15px;
  font-weight: 500;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.form-select:focus-within,
.form-control:focus-within {
  box-shadow: none;
  border-color: var(--primaryHEX);
  background-color: var(--thinBorder);
}

textarea.form-control {
  height: 134px;
  padding: 15px 16px;
}

.form-select {
  cursor: pointer;
  background-position: right 18px center;
}

.form-control[type=file]::-webkit-file-upload-button,
.form-control[type=file]::file-selector-button {
  padding: 17px 12px;
  font-size: 15px;
  border: none;
  background: none;
  color: var(--textColor);
}

.form-btn {
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px 8px;
  text-transform: uppercase;
  width: 100%;
  height: 55px;
  line-height: 24px;
  border-radius: 0px;
  padding: 14px 25px;
  letter-spacing: 0.5px;
  color: var(--lightColor);
  background-color: var(--primaryHEX);
  box-shadow: var(--btnBoxShadow);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.form-btn:focus {
  box-shadow: var(--btnBoxShadow);
}

.mc-table-responsive {
  overflow-x: auto;
}

.mc-table {
  width: 100%;
  border-collapse: collapse;
}
.mc-table.product tr th:first-child, .mc-table.product tr td:first-child {
  max-width: 90px;
  min-width: fit-content;
}
.mc-table.product tr th:nth-child(2), .mc-table.product tr td:nth-child(2) {
  max-width: 180px;
  min-width: fit-content;
}

.mc-table-head {
  border-top: 1px solid var(--lightBorder);
  border-bottom: 1px solid var(--lightBorder);
}
.mc-table-head.sticky {
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
}
.mc-table-head.gray {
  background-color: var(--thinBorder);
}
.mc-table-head.primary {
  border: none;
  color: var(--lightColor);
  background: linear-gradient(to top, var(--primaryHEX), #2b77e5);
}
.mc-table-head.primary tr th {
  padding: 13px 15px;
  border-right: 1px solid #2b77e5;
}
.mc-table-head.primary tr th:last-child {
  border-right: none;
}
.mc-table-head tr th {
  max-width: 120px;
  min-width: fit-content;
  padding: 15px 15px;
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  border-right: 1px solid var(--lightBorder);
}
.mc-table-head tr th:last-child {
  border-right: none;
}

.mc-table-body.even tr:nth-child(even) {
  background-color: var(--thinGray);
}
.mc-table-body.odd tr:nth-child(odd) {
  background-color: var(--thinGray);
}
.mc-table-body tr td {
  max-width: 120px;
  min-width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 15px 15px;
  font-size: 14px;
  font-weight: 400;
  border-right: 1px solid var(--lightBorder);
  border-bottom: 1px solid var(--lightBorder);
}
.mc-table-body tr td:last-child {
  border-right: none;
}

.mc-table-check {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px 12px;
}
.mc-table-check input {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  outline: 2px solid var(--lightBorder);
}
.mc-table-check p {
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mc-table-profile {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px 5px;
}
.mc-table-profile img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 3px 10px 0px rgba(var(--blackRGB), 15%);
}
.mc-table-profile p {
  min-width: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mc-table-product {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px 8px;
}
.mc-table-product.sm img {
  width: 35px;
  height: 35px;
}
.mc-table-product.md img {
  width: 40px;
  height: 40px;
}
.mc-table-product img {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 8px;
  object-fit: cover;
  border: 1px solid var(--lightBorder);
  box-shadow: 0px 7px 15px 0px rgba(var(--blackRGB), 6%);
}
.mc-table-product h6 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mc-table-product p {
  font-size: 13px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mc-table-group {
  min-width: 70px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mc-table-review {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px 1px;
}
.mc-table-review i {
  font-size: 14px;
  color: #edb213;
}
.mc-table-review span {
  font-size: 12px;
}

.mc-table-icon {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px 10px;
}
.mc-table-icon.role {
  gap: 4px;
}
.mc-table-icon.role i {
  font-size: 20px;
}
.mc-table-icon.role i.purple {
  color: #be0ee1;
}
.mc-table-icon.role i.yellow {
  color: #e1940e;
}
.mc-table-icon.role i.green {
  color: #1a9f53;
}
.mc-table-icon.role i.blue {
  color: #2b77e5;
}
.mc-table-icon.role i.red {
  color: #f11133;
}
.mc-table-icon i {
  color: var(--primaryHEX);
}
.mc-table-icon p {
  font-weight: 500;
  text-transform: capitalize;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mc-table-icon a {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-table-icon a:hover {
  color: var(--primaryHEX);
}

.mc-table-price p {
  color: #ff6179;
  font-weight: 500;
}

.mc-table-rating {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 3px;
}
.mc-table-rating i {
  font-size: 18px;
  color: #edb213;
}
.mc-table-rating h3 {
  font-size: 14px;
  line-height: 18px;
}
.mc-table-rating p {
  font-size: 13px;
  line-height: 18px;
}

.mc-table-badge {
  line-height: 22px;
  border-radius: 6px;
  padding: 0px 7px 1px;
  width: fit-content;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
}
.mc-table-badge.purple {
  color: #a808c3;
  background: #f6caff;
}
.mc-table-badge.blue {
  color: #2262d3;
  background: #c1e1fc;
}
.mc-table-badge.green {
  color: #187d44;
  background: #bdf5d3;
}
.mc-table-badge.yellow {
  color: #ae640f;
  background: #f9ef8e;
}
.mc-table-badge.red {
  color: #e00b2b;
  background: #ffc4cd;
}

.mc-table-action {
  display: flex;
  gap: 6px;
}
.mc-table-action a,
.mc-table-action button {
  font-size: 16px;
  padding: 6px 6px;
  border-radius: 6px;
  color: var(--lightColor);
  background-color: var(--lightGray);
}

.light_mode .mc-table-action a.download,
.light_mode .mc-table-action button.download {
  color: #2b77e5;
  background-color: #dcedfd;
}
.light_mode .mc-table-action a.view,
.light_mode .mc-table-action button.view {
  color: #be0ee1;
  background-color: #fbe5ff;
}
.light_mode .mc-table-action a.edit,
.light_mode .mc-table-action button.edit {
  color: #1a9f53;
  background-color: #ddfbe9;
}
.light_mode .mc-table-action a.chat,
.light_mode .mc-table-action button.chat {
  color: #1a9f53;
  background-color: #ddfbe9;
}
.light_mode .mc-table-action a.delete,
.light_mode .mc-table-action button.delete {
  color: #f11133;
  background-color: #ffdfe4;
}
.light_mode .mc-table-action a.block,
.light_mode .mc-table-action button.block {
  color: #f11133;
  background-color: #ffdfe4;
}

.dark_mode .mc-table-action a.view,
.dark_mode .mc-table-action button.view {
  background-color: #de2fff;
}
.dark_mode .mc-table-action a.edit,
.dark_mode .mc-table-action button.edit {
  background-color: #27bf68;
}
.dark_mode .mc-table-action a.chat,
.dark_mode .mc-table-action button.chat {
  background-color: #27bf68;
}
.dark_mode .mc-table-action a.delete,
.dark_mode .mc-table-action button.delete {
  background-color: #ff304f;
}
.dark_mode .mc-table-action a.block,
.dark_mode .mc-table-action button.block {
  background-color: #ff304f;
}
.dark_mode .mc-table-action a.download,
.dark_mode .mc-table-action button.download {
  background-color: #4094f1;
}

.mc-label-field-group {
  display: flex;
  align-items: center;
  gap: 8px;
}
.mc-label-field-group.label-row {
  flex-direction: row;
  justify-content: start;
}
.mc-label-field-group.label-col {
  flex-direction: column;
  align-items: start;
}
.mc-label-field-group.label-row-reverse {
  flex-direction: row-reverse;
  justify-content: start;
}
.mc-label-field-group.label-col-reverse {
  flex-direction: column-reverse;
  align-items: start;
}

.mc-label-field-title {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--headingColor);
}

.mc-label-field-input,
.mc-label-field-select,
.mc-label-field-textarea {
  padding: 0px 15px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid var(--deepBorder);
  background-color: var(--lightGray);
}
.mc-label-field-input.w-sm,
.mc-label-field-select.w-sm,
.mc-label-field-textarea.w-sm {
  width: 200px;
}
.mc-label-field-input.w-md,
.mc-label-field-select.w-md,
.mc-label-field-textarea.w-md {
  width: 300px;
}
.mc-label-field-input.w-lg,
.mc-label-field-select.w-lg,
.mc-label-field-textarea.w-lg {
  width: 400px;
}
.mc-label-field-input.w-xl,
.mc-label-field-select.w-xl,
.mc-label-field-textarea.w-xl {
  width: 500px;
}
.mc-label-field-input.w-100,
.mc-label-field-select.w-100,
.mc-label-field-textarea.w-100 {
  width: 100%;
}
.mc-label-field-input.w-fit,
.mc-label-field-select.w-fit,
.mc-label-field-textarea.w-fit {
  width: fit-content;
}
.mc-label-field-input.h-sm,
.mc-label-field-select.h-sm,
.mc-label-field-textarea.h-sm {
  height: 42px;
}
.mc-label-field-input.h-md,
.mc-label-field-select.h-md,
.mc-label-field-textarea.h-md {
  height: 45px;
}
.mc-label-field-input.h-lg,
.mc-label-field-select.h-lg,
.mc-label-field-textarea.h-lg {
  height: 48px;
}
.mc-label-field-input.h-xl,
.mc-label-field-select.h-xl,
.mc-label-field-textarea.h-xl {
  height: 50px;
}
.mc-label-field-input.h-text-sm,
.mc-label-field-select.h-text-sm,
.mc-label-field-textarea.h-text-sm {
  height: 120px;
}
.mc-label-field-input.h-text-md,
.mc-label-field-select.h-text-md,
.mc-label-field-textarea.h-text-md {
  height: 150px;
}
.mc-label-field-input.h-text-lg,
.mc-label-field-select.h-text-lg,
.mc-label-field-textarea.h-text-lg {
  height: 180px;
}
.mc-label-field-input.h-text-xl,
.mc-label-field-select.h-text-xl,
.mc-label-field-textarea.h-text-xl {
  height: 210px;
}
.mc-label-field-input.h-multiple,
.mc-label-field-select.h-multiple,
.mc-label-field-textarea.h-multiple {
  height: 100px;
  padding: 12px 15px;
  background-image: none;
}

.mc-label-field-textarea {
  padding: 10px 15px;
}

.mc-label-field-select {
  text-transform: capitalize;
  background-image: url(../images/dropdown.svg);
  background-position: right 12px center;
  background-repeat: no-repeat;
  background-size: 12px;
  appearance: none;
  cursor: pointer;
}

.mc-icon-field {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px;
  padding: 0px 0px 0px 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid var(--deepBorder);
  background-color: var(--lightBG);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-icon-field:focus-within {
  border-color: var(--primaryHEX);
}
.mc-icon-field:focus-within i {
  color: var(--primaryHEX);
}
.mc-icon-field:focus-within button {
  opacity: 1;
}
.mc-icon-field.textarea {
  align-items: start;
}
.mc-icon-field.textarea i {
  margin-top: 14px;
}
.mc-icon-field.textarea textarea {
  padding: 12px;
  resize: none;
}
.mc-icon-field.gray {
  background-color: var(--thinGray);
  border-color: var(--deepBorder);
}
.mc-icon-field.light {
  background-color: #f8f8f8;
  border-color: #f0f0f0;
}
.mc-icon-field.white {
  background-color: var(--lightBG);
  border-color: #f8f8f8;
}
.mc-icon-field.w-sm {
  width: 200px;
}
.mc-icon-field.w-md {
  width: 300px;
}
.mc-icon-field.w-lg {
  width: 400px;
}
.mc-icon-field.w-xl {
  width: 500px;
}
.mc-icon-field.w-100 {
  width: 100%;
}
.mc-icon-field.w-fit {
  width: fit-content;
}
.mc-icon-field.h-sm {
  height: 42px;
}
.mc-icon-field.h-md {
  height: 45px;
}
.mc-icon-field.h-lg {
  height: 48px;
}
.mc-icon-field.h-xl {
  height: 50px;
}
.mc-icon-field.h-text-sm {
  height: 120px;
}
.mc-icon-field.h-text-md {
  height: 150px;
}
.mc-icon-field.h-text-lg {
  height: 180px;
}
.mc-icon-field.h-text-xl {
  height: 210px;
}
.mc-icon-field input,
.mc-icon-field select,
.mc-icon-field textarea {
  width: 100%;
  height: 100%;
  padding: 0px 12px;
  background-color: var(--thinGray);
}
.mc-icon-field select {
  font-size: 14px;
  text-transform: capitalize;
  background-image: url(../images/dropdown.svg);
  background-position: right 0px center;
  background-repeat: no-repeat;
  background-size: 12px;
  appearance: none;
  cursor: pointer;
}
.mc-icon-field i,
.mc-icon-field button {
  font-size: 20px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-icon-field button {
  opacity: 0;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.modal {
  background-color: rgba(var(--blackRGB), 50%);
}

.modal-close {
  position: absolute;
  top: -12px;
  right: -12px;
}
.modal-close i {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  color: var(--textColor);
  background-color: var(--thinGray);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.modal-close i:hover {
  color: var(--lightColor);
  background: #ff304f;
}

.modal-dialog-flexit {
  margin: 100px auto;
  max-width: fit-content;
}

.modal-content-flexit {
  border-radius: 8px;
}

.modal-header-flexit {
  padding: 0px;
  border: none;
  margin-bottom: 30px;
}

.modal-title {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--headingColor);
}

.modal-footer-flexit {
  padding: 0px;
  border: none;
  margin-top: 35px;
  justify-content: center;
  gap: 15px;
}
.modal-footer-flexit > * {
  margin: 0px;
}

.mc-user-modal {
  width: 450px;
  padding: 0px 40px 40px;
}
.mc-user-modal img {
  width: 100px;
  height: 100px;
  line-height: 0;
  border-radius: 50%;
  text-align: center;
  border: 12px solid var(--lightColor);
  margin: -45px auto 0px;
  display: block;
}
.mc-user-modal h4 {
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
}
.mc-user-modal p {
  font-size: 15px;
  margin-bottom: 25px;
  text-align: center;
}

.mc-alert-modal {
  width: 450px;
  padding: 0px 35px 35px;
  text-align: center;
}
.mc-alert-modal i {
  font-size: 45px;
  margin-top: -35px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
  text-align: center;
  text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.12);
  color: #f11133;
  background-color: var(--lightBG);
}
.mc-alert-modal h3 {
  font-size: 24px;
  font-weight: 800;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.mc-alert-modal p {
  font-size: 18px;
}

.contenedorModal {
  display: flex;
  flex-direction: column;
}
.contenedorModal h6 {
  color: var(--colorSetting);
}

.containerImgCombos {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
.containerImgCombos .imgModal {
  height: 350px;
  width: 100%;
  object-fit: cover;
}
.containerImgCombos .anchoTabla {
  margin-top: 20px;
  width: 80%;
}
.containerImgCombos .anchoTabla .btnOrden {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.containerImgCombos .anchoTabla .btnOrden button {
  background-color: var(--primaryHEX);
  color: var(--colorSetting);
  padding: 20px 170px;
  margin-top: 10px;
}
.containerImgCombos .anchoTabla .tituloTabla {
  font-size: 22px;
  margin: 0;
  padding: 0;
}
.containerImgCombos .anchoTabla .lineaTabla {
  width: 100%;
  height: 2px;
  background-color: var(--colorSetting);
  margin: 20px 0px;
}
.containerImgCombos .anchoTabla .tablaReact thead tr .colorLetra {
  color: var(--colorSetting);
}
.containerImgCombos .anchoTabla .tablaReact tbody tr td .colorLetra {
  color: var(--colorSetting);
}
.containerImgCombos .anchoTabla .tablaReact tbody tr td::before {
  color: var(--colorSetting);
}
.containerImgCombos .containerSelects {
  display: flex;
  flex-direction: column;
}
.containerImgCombos .containerSelects div {
  width: 400px;
}
.containerImgCombos .containerSelects select {
  border: 1px solid #666;
  width: 300px;
  margin-bottom: 25px;
  min-width: 300px;
}
.containerImgCombos .containerSelects .divFil {
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
}
.containerImgCombos .containerSelects .divFil .formConsulta {
  color: var(--colorSetting);
}

@keyframes entrada {
  0% {
    left: -50%;
  }
  100% {
    left: 0;
  }
}
.modal-lateral-flexit {
  max-width: 100%;
  height: 100% !important;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  position: relative;
  left: 0;
}
.modal-lateral-flexit .entrada-izq-der-flexit {
  left: 0;
  animation: entrada 0.5s ease;
}
.modal-lateral-flexit .tamano-modal-flexit {
  max-width: 600px;
  min-height: 100%;
  height: auto !important;
  width: 600px !important;
}
.modal-lateral-flexit .tamano-modal-flexit .modal-body .container-venta-flexit {
  padding: 5px;
  border-bottom: 2px solid var(--primaryGreen);
  overflow-y: auto;
  height: 20rem;
}
.modal-lateral-flexit .tamano-modal-flexit .modal-body .container-venta-flexit .container-producto-flexit {
  border-top: 2px solid var(--primaryGreen);
}
.modal-lateral-flexit .tamano-modal-flexit .modal-body .container-venta-flexit .container-producto-flexit .menos {
  font-size: 18px;
}
.modal-lateral-flexit .tamano-modal-flexit .modal-body .container-venta-flexit .container-producto-flexit .mas {
  font-size: 18px;
}
.modal-lateral-flexit .tamano-modal-flexit .modal-body .container-venta-flexit .container-producto-flexit .boton-eliminar-flexit {
  background-color: #f8d7da;
  color: #721c24;
}
.modal-lateral-flexit .tamano-modal-flexit .modal-body div #tabla_wrapper .row .col-sm-12 #tabla_filter label {
  font-size: 12px;
}
.modal-lateral-flexit .tamano-modal-flexit .modal-body div #tabla_wrapper .row .col-sm-12 #tabla_paginate {
  font-size: 12px;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos .anchoTabla {
  margin-top: 20px;
  width: 80%;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos .anchoTabla .btnOrden {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos .anchoTabla .btnOrden button {
  background-color: var(--primaryHEX);
  color: var(--colorSetting);
  padding: 20px 170px;
  margin-top: 10px;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos .anchoTabla .tituloTabla {
  font-size: 22px;
  margin: 0;
  padding: 0;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos .anchoTabla .lineaTabla {
  width: 100%;
  height: 2px;
  background-color: var(--colorSetting);
  margin: 20px 0px;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos .anchoTabla .tablaReact thead tr .colorLetra {
  color: var(--colorSetting);
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos .anchoTabla .tablaReact tbody tr td .colorLetra {
  color: var(--colorSetting);
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos .containerSelects {
  display: flex;
  flex-direction: column;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos .containerSelects div {
  width: 400px;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos .containerSelects select {
  border: 1px solid #666;
  width: 300px;
  margin-bottom: 25px;
  min-width: 300px;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos .containerSelects .divFil {
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos .containerSelects .divFil .formConsulta {
  color: var(--colorSetting);
  width: 300px;
}

@media (max-width: 495px) {
  .containerImgCombos .anchoTabla {
    margin-top: 20px;
    width: 80%;
  }
  .containerImgCombos .anchoTabla .btnOrden {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .containerImgCombos .anchoTabla .btnOrden button {
    padding: 20px 90px;
    margin-top: 10px;
  }
}
@media (min-width: 713px) {
  .containerImgCombos .containerSelects {
    justify-content: center;
  }
}
.btnCerrarModal {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: 10px;
}

.backgroundModal {
  background-color: var(--bodyBG);
}

.modal-lateral-flexit {
  max-width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  position: relative;
  left: 0;
}
.modal-lateral-flexit .tamano-modal-flexit {
  max-width: 450px;
  height: 100%;
  width: auto;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  flex-wrap: wrap;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos2 input {
  color: var(--colorSetting);
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos2 input:focus {
  color: var(--colorSetting);
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos2 .containerSelects2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos2 .containerSelects2 select {
  border: 1px solid #666;
  width: 400px;
  margin-bottom: 25px;
  min-width: 300px;
}
.modal-lateral-flexit .tamano-modal-flexit .containerImgCombos2 .containerSelects2 .divFil {
  display: flex;
  flex-direction: column;
}

.mc-btn {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  text-align: center;
  padding: 14px 22px;
  letter-spacing: 0.5px;
  border-radius: 8px;
  color: var(--lightColor);
  box-shadow: var(--btnBoxShadow);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-btn:hover {
  opacity: 0.8;
  color: var(--lightColor);
}
.mc-btn i {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 6px;
}
.mc-btn span {
  vertical-align: middle;
}
.mc-btn.primary {
  background-color: #120144;
}
.mc-btn.yellow {
  background-color: #f4d02b;
}
.mc-btn.purple {
  background-color: #be0ee1;
}
.mc-btn.orange {
  background-color: #ff7300;
}
.mc-btn.green {
  background-color: #1a9f53;
}
.mc-btn.blue {
  background-color: #2b77e5;
}
.mc-btn.gray {
  background-color: #818181;
}
.mc-btn.red {
  background-color: #f11133;
}
.mc-btn.sm {
  font-size: 11px;
  padding: 12px 18px;
}
.mc-btn.sm i {
  font-size: 16px;
}
.mc-btn.h-sm {
  height: 40px;
}
.mc-btn.h-md {
  height: 45px;
}
.mc-btn.h-lg {
  height: 50px;
}
.mc-btn.h-xl {
  height: 55px;
}

.btn-flexit {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 12px 22px;
  border-radius: 8px;
  color: var(--lightColor);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.btn-flexit:hover {
  color: var(--lightColor);
}
.btn-flexit.btn-sm-flexit {
  font-size: 11px;
  font-weight: 500;
  padding: 8px 15px;
}

.btn-filtro-flexit {
  background-color: var(--primaryGreen);
  color: var(--colorSetting);
  border: 1px solid var(--primaryGreen);
}

.btn-filtro-flexit:focus {
  background-color: var(--primaryGreen);
  border: 1px solid var(--primaryGreen);
  color: var(--colorSetting);
}

.btn-filtro-flexit:hover {
  filter: opacity(0.8);
  background-color: var(--primaryGreen);
  border: 1px solid white;
  color: var(--colorSetting);
}

.btn-rechazar-flexit {
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
}

.btn-rechazar-flexit:hover {
  filter: opacity(0.8);
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
}

.btn-rechazar-flexit:focus {
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
}

.btn-resetear-flexit {
  background-color: #fff3cd;
  color: #856404;
  border-color: #ffeeba;
}

.btn-resetear-flexit:hover {
  filter: opacity(0.8);
  background-color: #fff3cd;
  color: #856404;
  border-color: #ffeeba;
}

.btn-resetear-flexit:focus {
  background-color: #fff3cd;
  color: #856404;
  border-color: #ffeeba;
}

.mc-spinner {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
.mc-spinner::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: url("../images/pattern.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.1;
}
.mc-spinner img {
  height: 70px;
}

.mc-spinner-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.mc-spinner-group h3 {
  font-size: 20px;
  text-transform: uppercase;
  color: var(--primaryHEX);
}

.background-dropdown-flexit {
  background-color: var(--paperBG);
}
.background-dropdown-flexit .item-flexit {
  color: var(--colorSetting);
}
.background-dropdown-flexit .item-flexit:hover {
  background-color: var(--grayDropdown);
}

.mc-dropdown-toggle.btn {
  border: inherit;
  padding: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  border-color: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  background-color: inherit;
  border-radius: inherit;
  line-height: 0px;
  box-shadow: inherit;
}
.mc-dropdown-toggle.btn:hover {
  color: inherit;
}
.mc-dropdown-toggle.btn:focus {
  box-shadow: none;
}
.mc-dropdown-toggle.dropdown-toggle::after {
  display: none;
}

.mc-dropdown-paper {
  padding: 10px;
  border-radius: 8px;
  background-color: var(--paperBG);
  box-shadow: var(--paperBoxShadow);
  border: 1px solid var(--lightBorder);
}

.mc-dropdown-menu {
  width: 100%;
  padding: 7px 8px;
  font-size: 14px;
  border-radius: 6px;
  white-space: nowrap;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px 5px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-dropdown-menu:hover {
  background-color: var(--hoverBG);
}
.mc-dropdown-menu i {
  font-size: 18px;
}

.mc-fieldset {
  min-width: inherit;
  padding: 8px 15px;
  margin: 0px;
  border-radius: 8px;
  border: 1px solid var(--hardBorder);
  background-color: var(--lightGray);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-fieldset:focus-within {
  border-color: var(--primaryHEX);
}
.mc-fieldset:focus-within legend {
  color: var(--primaryHEX);
}
.mc-fieldset legend {
  float: inherit;
  width: inherit;
  line-height: inherit;
  padding: 0px 5px;
  line-height: 1px;
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-fieldset input,
.mc-fieldset select {
  padding: 0px 5px;
  font-size: 15px;
  font-weight: 500;
}
.mc-fieldset textarea {
  padding: 18px 5px;
  line-height: 20px;
  font-size: 15px;
  font-weight: 500;
}
.mc-fieldset .w-sm {
  width: 200px;
}
.mc-fieldset .w-md {
  width: 300px;
}
.mc-fieldset .w-lg {
  width: 400px;
}
.mc-fieldset .w-xl {
  width: 500px;
}
.mc-fieldset .w-100 {
  width: 100%;
}
.mc-fieldset .w-fit {
  width: fit-content;
}
.mc-fieldset .h-sm {
  height: 35px;
}
.mc-fieldset .h-md {
  height: 40px;
}
.mc-fieldset .h-lg {
  height: 45px;
}
.mc-fieldset .h-xl {
  height: 50px;
}
.mc-fieldset .h-text-sm {
  height: 120px;
}
.mc-fieldset .h-text-md {
  height: 150px;
}
.mc-fieldset .h-text-lg {
  height: 180px;
}
.mc-fieldset .h-text-xl {
  height: 210px;
}

.mc-duel-text {
  display: flex;
  flex-direction: column;
}
.mc-duel-text.xs {
  gap: 4px;
}
.mc-duel-text.xs .mc-duel-text-title {
  font-size: 14px;
}
.mc-duel-text.xs .mc-duel-text-descrip {
  font-size: 12px;
  font-weight: 500;
}
.mc-duel-text.sm {
  gap: 6px;
}
.mc-duel-text.sm .mc-duel-text-title {
  font-size: 16px;
}
.mc-duel-text.sm .mc-duel-text-descrip {
  font-size: 14px;
}
.mc-duel-text.md {
  gap: 8px;
}
.mc-duel-text.md .mc-duel-text-title {
  font-size: 18px;
}
.mc-duel-text.md .mc-duel-text-descrip {
  font-size: 15px;
}
.mc-duel-text.lg {
  gap: 10px;
}
.mc-duel-text.lg .mc-duel-text-title {
  font-size: 20px;
}
.mc-duel-text.lg .mc-duel-text-descrip {
  font-size: 17px;
}

.mc-duel-text-title {
  font-weight: 600;
  line-height: 1.2;
  text-transform: capitalize;
}

.mc-duel-text-descrip {
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  text-transform: lowercase;
}

.mc-paginate {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}
@media (max-width: 575px) {
  .mc-paginate {
    flex-direction: column;
    gap: 15px 0px;
  }
}

.mc-paginate-title {
  font-size: 14px;
  font-weight: 500;
}

.mc-paginate-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px 8px;
}

.mc-paginate-item {
  cursor: pointer;
  background-color: var(--lightGray);
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-paginate-item:hover {
  color: var(--lightColor);
  background-color: var(--primaryHEX);
}
.mc-paginate-item i {
  line-height: 35px;
  font-size: 20px;
}
.mc-paginate-item.active {
  color: var(--lightColor);
  background-color: var(--primaryHEX);
}

.mc-breadcrumb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}
@media (max-width: 575px) {
  .mc-breadcrumb {
    flex-direction: column;
    gap: 15px 0px;
  }
}

.mc-breadcrumb-title {
  text-transform: capitalize;
}

.mc-breadcrumb-list {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px;
}

.mc-breadcrumb-item {
  font-size: 15px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: var(--textColor);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-breadcrumb-item::after {
  content: "~";
  font-size: 16px;
  margin: 0px 7px;
  color: #55DFB2;
}
.mc-breadcrumb-item:last-child::after {
  display: none;
}

.mc-breadcrumb-link {
  color: #55DFB2;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-breadcrumb-link:hover {
  color: #27CCCA;
  text-decoration: underline;
}

.mc-file-upload {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  border-radius: 8px;
  color: var(--textColor);
  background-color: var(--lightGray);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-file-upload:hover {
  color: var(--lightColor);
  background-color: var(--primaryHEX);
}
.mc-file-upload.button label {
  cursor: pointer;
  padding: 10px 22px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.mc-file-upload.button label i {
  font-size: 20px;
}
.mc-file-upload input {
  width: 100%;
  height: 100%;
  visibility: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.mc-round-avatar {
  border-style: solid;
  width: fit-content;
  border-radius: 50%;
  border-color: var(--primaryHEX);
}
.mc-round-avatar img {
  object-fit: cover;
  border-radius: 50%;
}
.mc-round-avatar.xs {
  border-width: 1px;
}
.mc-round-avatar.xs img {
  width: 37px;
  height: 37px;
  border: 2px solid transparent;
}
.mc-round-avatar.sm {
  border-width: 2px;
}
.mc-round-avatar.sm img {
  width: 55px;
  height: 55px;
  border: 3px solid transparent;
}
.mc-round-avatar.md {
  border-width: 2px;
}
.mc-round-avatar.md img {
  width: 85px;
  height: 85px;
  border: 3px solid transparent;
}
.mc-round-avatar.lg {
  border-width: 2px;
}
.mc-round-avatar.lg img {
  width: 115px;
  height: 115px;
  border: 3px solid transparent;
}
.mc-round-avatar.xl {
  border-width: 2px;
}
.mc-round-avatar.xl img {
  width: 145px;
  height: 145px;
  border: 3px solid transparent;
}

.mc-divide-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: capitalize;
}
.mc-divide-title::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--lightBorder);
}

.thin-scrolling {
  overflow-y: auto;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.thin-scrolling:hover::-webkit-scrollbar-thumb {
  background-color: var(--primaryHEX);
}
.thin-scrolling::-webkit-scrollbar {
  width: 3px;
}
.thin-scrolling::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: transparent;
}

.light_mode {
  --primaryHEX: #0858f7;
  --primaryGreen: #55DFB2;
  --lightColor: #ffffff;
  --textColor: #5e5d72;
  --activeColor: #0858f7;
  --placeholderColor: #9a9a9a;
  --headingColor: #403e57;
  --colorSetting: #000;
  --bodyBG: #ffffff;
  --cardBG: #ffffff;
  --paperBG: #ffffff;
  --lightBG: #ffffff;
  --thinBorder: #f8f8f8;
  --lightBorder: #f0f0f0;
  --deepBorder: #e4e4e4;
  --hardBorder: #d1d1d1;
  --thinGray: #f8f8f8;
  --lightGray: #f0f0f0;
  --grayDropdown: #a2a2a2;
  --deepGray: #e4e4e4;
  --hardGray: #d1d1d1;
  --hoverBG: #f1f1f1;
  --activeBG: #ebf6ff;
  --facebook: #3b5998;
  --linkedin: #0e76a8;
  --twitter: #00acee;
  --google: #E60023;
  --apple: #555555;
  --instagram: #F77737;
  --whatsapp: #25D366;
  --pinterest: #f0002a;
  --youtube: #FF0000;
  --primaryRGB: 8, 88, 247;
  --lightRGB: 255, 255, 255;
  --blackRGB: 0, 0, 0;
  --redRGB: 241, 17, 51;
  --darkRGB: 1, 12, 44;
  --grayRGB: 129, 129, 129;
  --blueRGB: 43, 119, 229;
  --greenRGB: 26, 159, 83;
  --purpleRGB: 190, 14, 225;
  --orangeRGB: 255, 115, 0;
  --yellowRGB: 225, 148, 14;
  --btnBoxShadow: 0px 8px 18px 0px rgba(var(--blackRGB), 12%);
  --cardBoxShadow: 0px 15px 25px 0px rgba(var(--blackRGB), 6%);
  --paperBoxShadow: 0px 15px 35px 0px rgba(var(--blackRGB), 10%);
}

.dark_mode {
  --primaryHEX: #1e66ff;
  --primaryGreen: #27CCCA;
  --lightColor: #ffffff;
  --textColor: #b4b4b4;
  --activeColor: #94b7ff;
  --placeholderColor: #acacac;
  --headingColor: #e4e4e4;
  --colorSetting: #fff;
  --bodyBG: #112143;
  --cardBG: #112143;
  --paperBG: #132140;
  --grayDropdown: #071739;
  --lightBG: #071739;
  --thinBorder: #253557;
  --lightBorder: #1b2b4d;
  --deepBorder: #253557;
  --hardBorder: #31436a;
  --thinGray: #071739;
  --lightGray: #1b2b4d;
  --deepGray: #00031f;
  --hardGray: #000015;
  --hoverBG: #253557;
  --activeBG: rgba(40, 129, 227, 0.100);
  --facebook: #3b5998;
  --linkedin: #0e76a8;
  --twitter: #00acee;
  --google: #E60023;
  --instagram: #F77737;
  --whatsapp: #25D366;
  --pinterest: #f0002a;
  --youtube: #FF0000;
  --apple: #555555;
  --primaryRGB: 35, 108, 255;
  --lightRGB: 17, 33, 67;
  --blackRGB: 0, 0, 0;
  --redRGB: 241, 17, 51;
  --darkRGB: 1, 12, 44;
  --grayRGB: 129, 129, 129;
  --blueRGB: 43, 119, 229;
  --greenRGB: 26, 159, 83;
  --purpleRGB: 190, 14, 225;
  --orangeRGB: 255, 115, 0;
  --yellowRGB: 225, 148, 14;
  --btnBoxShadow: 0px 8px 18px 0px rgba(var(--blackRGB), 12%);
  --cardBoxShadow: 0px 15px 25px 0px rgba(var(--blackRGB), 6%);
  --paperBoxShadow: 0px 15px 35px 0px rgba(var(--blackRGB), 10%);
}

.tablePrincipal {
  position: relative;
}

.botonAbrir {
  width: 90px;
  cursor: pointer;
}

button.dt-button,
div.dt-button,
a.dt-button,
input.dt-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin-left: 0.167em;
  margin-right: 0.167em;
  margin-bottom: 0.333em;
  padding: 0.1em 0.4em;
  border: 1px solid var(--primaryGreen);
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.88em;
  line-height: 1.6em;
  white-space: nowrap;
  overflow: hidden;
  background: var(--primaryGreen);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  outline: none;
  text-overflow: ellipsis;
}

button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled),
input.dt-button:hover:not(.disabled) {
  filter: opacity(0.8);
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin-left: 0.167em;
  margin-right: 0.167em;
  margin-bottom: 0.333em;
  padding: 0.2em 0.8em;
  border: 1px solid var(--primaryGreen);
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.88em;
  line-height: 1.6em;
  white-space: nowrap;
  overflow: hidden;
  background: var(--primaryGreen);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  outline: none;
  text-overflow: ellipsis;
}

/* button.dt-button:hover,
div.dt-button:hover,
a.dt-button:hover,
input.dt-button:hover {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin-left: .167em;
  margin-right: .167em;
  margin-bottom: .333em;
  padding: .2em .8em;
  border: 1px solid var(--primaryGreen);
  border-radius: 2px;
  cursor: pointer;
  font-size: .88em;
  line-height: 1.6em;
  white-space: nowrap;
  overflow: hidden;
  background: var(--primaryGreen);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  outline: none;
  text-overflow: ellipsis;
} */
.modalBackground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.th {
  font-size: 12px;
}

.th::first-letter {
  text-transform: uppercase;
}

table thead .width-opciones {
  width: 60px !important;
}
table thead tr {
  background-color: var(--primaryGreen);
}
table thead tr th {
  color: var(--colorSetting);
}
table tbody tr .child {
  color: var(--colorSetting);
}
table tbody tr:hover {
  color: var(--colorSetting);
}
table .width-opciones {
  width: 60px !important;
}
table .td {
  color: var(--colorSetting);
}
table .td span::after {
  display: none;
}
table .td span button {
  color: var(--primaryGreen);
  padding: 2px;
  transition: 0.5s all ease-in-out;
}
table .td span button:hover {
  transform: scale(1.2);
}
table .td span button:focus {
  transform: scale(1.2);
}

#table .td {
  white-space: inherit;
}

.modalTabla {
  position: absolute;
  left: 30%;
  top: 30%;
  width: 400px;
  min-height: 200px;
  overflow: auto;
  background-color: var(--bodyBG);
  border: 1px solid #D1D1D1;
  padding: 10px;
}
.modalTabla h6 {
  padding-bottom: 5px;
}
.modalTabla::-webkit-scrollbar {
  display: none;
}

.contenedorSet {
  background-color: var(--primaryGreen);
  padding: 2px 5px;
  border-radius: 5px;
}
.contenedorSet i {
  color: var(--colorSetting);
}

.dropdown-toggle {
  color: var(--colorSetting);
}

.cerrarModal {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  color: #fff;
  background-color: #4054D3;
  border-radius: 10px;
  text-align: center;
}

.tituloTabla {
  margin-bottom: 20px;
}

#table_length label {
  font-size: 12px;
}

#table_filter label {
  font-size: 12px;
}

#table_filter label input {
  font-size: 12px;
}

#table_info {
  font-size: 12px;
}

#table_paginate {
  font-size: 12px;
}

.horizontal {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.horizontal .row-tablero {
  min-width: 310px;
  width: auto;
}

.tooltip-flexit {
  padding: 4px;
  background-color: var(--primaryGreen);
  color: var(--colorSetting);
  margin-bottom: 5px;
}

.containerAccordion1 {
  display: flex;
  background-color: var(--cardBG);
  border: 1px solid var(--lightBorder);
  justify-content: space-between;
  flex-wrap: wrap;
  height: 200px;
  overflow: hidden;
}

.containerAccordionTotal1 {
  display: flex;
  background-color: var(--cardBG);
  border: 1px solid var(--lightBorder);
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;
}

.containerAccordion2 {
  display: flex;
  background-color: var(--cardBG);
  border: 1px solid var(--lightBorder);
  justify-content: space-between;
  flex-wrap: wrap;
  height: 200px;
  overflow: hidden;
}

.containerAccordionTotal2 {
  display: flex;
  background-color: var(--cardBG);
  border: 1px solid var(--lightBorder);
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;
}

.containerAccordion3 {
  display: flex;
  background-color: var(--cardBG);
  border: 1px solid var(--lightBorder);
  justify-content: space-between;
  flex-wrap: wrap;
  height: 200px;
  overflow: hidden;
}

.containerAccordionTotal3 {
  display: flex;
  background-color: var(--cardBG);
  border: 1px solid var(--lightBorder);
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;
}

.container .container-fluid .text-center .row .formControl {
  color: var(--colorSetting);
  width: 180px;
  margin-left: 5px;
}

.containerPendiente {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.containerPendiente .flex {
  display: flex;
  flex-wrap: wrap;
}

.sinOrdenes {
  margin-top: 5px;
  width: 100%;
  height: 100%;
  background-color: var(--cardBG);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.backgroundSpinner {
  color: var(--primaryGreen) !important;
}

.containerSpinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-left-flexit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 80px;
  overflow: auto;
}

.background-container-acordiones-flexit {
  background-color: var(--cardBG);
}

.gilroy-regular {
  font-family: "Gilroy", sans-serif;
}

.gilroy-light {
  font-family: "Gilroy-light", sans-serif;
}

.gilroy-medium {
  font-family: "Gilroy-medium", sans-serif;
}

.gilroy-bold {
  font-family: "Gilroy-bold", sans-serif;
}

.raleway-regular {
  font-family: "Raleway", sans-serif;
}

.raleway-light {
  font-family: "Raleway-light", sans-serif;
}

.raleway-medium {
  font-family: "Raleway-medium", sans-serif;
}

.raleway-bold {
  font-family: "Raleway-bold", sans-serif;
}

.color-pendiente-flexit {
  color: #383d41;
}

.color-encamino-flexit {
  color: #856404;
}

.color-confirmado-flexit {
  color: #155724;
}

.color-rechazado-flexit {
  color: #721c24;
}

.pFiltro {
  font-size: 12px;
  padding: 2px 6px;
}

.filtros-aplicados-flexit {
  font-size: 12px;
  padding: 2px 6px;
  background-color: var(--primaryGreen);
  border-radius: 10px;
  color: black;
  margin: 0px 3px;
}

.mc-card {
  padding: 22px;
  border-radius: 8px;
  background-color: var(--cardBG);
  border: 1px solid var(--lightBorder);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
@media (max-width: 575px) {
  .mc-card {
    padding: 20px 15px;
  }
}
.mc-card:hover {
  box-shadow: 0px 15px 25px 0px rgba(var(--blackRGB), 6%);
}

.mc-card-header {
  margin-bottom: 18px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 0px;
}

.mc-card-title {
  line-height: 27px;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
}

.mc-sales-card {
  border-radius: 8px;
  background: linear-gradient(to bottom, var(--primaryHEX), #2262d3);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-sales-card:hover {
  box-shadow: 0px 15px 25px 0px rgba(var(--blackRGB), 6%);
}
.mc-sales-card .mc-card-title {
  color: var(--lightColor);
}
.mc-sales-card .mc-dropdown-toggle i {
  color: var(--lightColor);
}

.mc-sales-card-group {
  padding: 22px;
}

.mc-sales-card-amount {
  display: flex;
  gap: 5px;
  margin-bottom: 6px;
}
.mc-sales-card-amount.red p {
  color: #ff9baa;
}
.mc-sales-card-amount.green p {
  color: #89ecb3;
}
.mc-sales-card-amount h3 {
  font-size: 26px;
  color: var(--lightColor);
}
.mc-sales-card-amount p {
  align-self: flex-end;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.mc-sales-card-amount p i {
  font-size: 15px;
}

.mc-sales-card-compare {
  font-size: 13px;
  color: #c1e1fc;
}

.mc-revenue-card-header {
  margin-bottom: 28px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}
.mc-revenue-card-header .mc-icon-field {
  margin-bottom: 0px;
  background-color: var(--thinGray);
  border-color: var(--lightBorder);
}

.mc-revenue-card-group {
  margin-bottom: 25px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 25px;
  flex-wrap: wrap;
}

.mc-revenue-card-report {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.mc-revenue-card-report i {
  font-size: 35px;
  color: var(--primaryHEX);
}
.mc-revenue-card-report i.blue {
  background: -webkit-linear-gradient(#2b77e5, #64b3f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mc-revenue-card-report i.green {
  background: -webkit-linear-gradient(#1a9f53, #4eda89);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mc-revenue-card-report i.purple {
  background: -webkit-linear-gradient(#be0ee1, #ed68ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mc-revenue-card-report h3 {
  font-size: 20px;
}
.mc-revenue-card-report h3 span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 5px;
  color: var(--textColor);
}

.mc-ecommerce-card {
  position: relative;
  padding: 22px;
  border-radius: 8px;
  background-color: var(--lightColor);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-ecommerce-card:hover {
  box-shadow: 0px 15px 25px 0px rgba(var(--blackRGB), 8%);
}
.mc-ecommerce-card.green {
  background: linear-gradient(to left, #4eda89, #1a9f53);
}
.mc-ecommerce-card.green .mc-ecommerce-card-icon {
  color: #89ecb3;
  background: linear-gradient(#27bf68, #1a9f53);
}
.mc-ecommerce-card.green .mc-ecommerce-card-trend {
  color: #1a9f53;
}
.mc-ecommerce-card.green .mc-ecommerce-card-compare mark {
  background-color: #187d44;
}
.mc-ecommerce-card.green .mc-dropdown-toggle {
  color: #187d44;
}
.mc-ecommerce-card.purple {
  background: linear-gradient(to left, #ed68ff, #be0ee1);
}
.mc-ecommerce-card.purple .mc-ecommerce-card-icon {
  color: #f3a0ff;
  background: linear-gradient(#de2fff, #be0ee1);
}
.mc-ecommerce-card.purple .mc-ecommerce-card-trend {
  color: #be0ee1;
}
.mc-ecommerce-card.purple .mc-ecommerce-card-compare mark {
  background-color: #a808c3;
}
.mc-ecommerce-card.purple .mc-dropdown-toggle {
  color: #a808c3;
}
.mc-ecommerce-card.blue {
  background: linear-gradient(to left, #64b3f6, #2b77e5);
}
.mc-ecommerce-card.blue .mc-ecommerce-card-icon {
  color: #96cefa;
  background: linear-gradient(#4094f1, #2b77e5);
}
.mc-ecommerce-card.blue .mc-ecommerce-card-trend {
  color: #2b77e5;
}
.mc-ecommerce-card.blue .mc-ecommerce-card-compare mark {
  background-color: #2262d3;
}
.mc-ecommerce-card.blue .mc-dropdown-toggle {
  color: #2262d3;
}
.mc-ecommerce-card.yellow {
  background: linear-gradient(to left, #f4d02b, #e1940e);
}
.mc-ecommerce-card.yellow .mc-ecommerce-card-icon {
  color: #f6e053;
  background: linear-gradient(#edb213, #e1940e);
}
.mc-ecommerce-card.yellow .mc-ecommerce-card-trend {
  color: #e1940e;
}
.mc-ecommerce-card.yellow .mc-ecommerce-card-compare mark {
  background-color: #ae640f;
}
.mc-ecommerce-card.yellow .mc-dropdown-toggle {
  color: #ae640f;
}

.mc-ecommerce-card-trend {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 1;
  font-size: 150px;
  opacity: 0.8;
}

.mc-ecommerce-card-head {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 0px;
  margin-bottom: 40px;
}

.mc-ecommerce-card-meta {
  font-size: 32px;
  font-weight: 700;
  position: relative;
  z-index: 1;
  color: var(--lightColor);
}
.mc-ecommerce-card-meta span {
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
}

.mc-ecommerce-card-icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 8px;
  text-align: center;
  font-size: 28px;
}

.mc-ecommerce-card-foot {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}

.mc-ecommerce-card-compare {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px 5px;
  color: var(--lightColor);
}
.mc-ecommerce-card-compare mark {
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: var(--lightColor);
}
.mc-ecommerce-card-compare span {
  font-size: 14px;
  text-transform: capitalize;
}

.mc-analytics-card {
  padding: 22px;
  border-radius: 8px;
  background-color: var(--cardBG);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-analytics-card:hover {
  box-shadow: var(--cardBoxShadow);
}
.mc-analytics-card.purple {
  background: linear-gradient(#ed68ff, #be0ee1);
}
.mc-analytics-card.yellow {
  background: linear-gradient(#f4d02b, #e1940e);
}
.mc-analytics-card.green {
  background: linear-gradient(#4eda89, #1a9f53);
}
.mc-analytics-card.blue {
  background: linear-gradient(#64b3f6, #2b77e5);
}
.mc-analytics-card.red {
  background: linear-gradient(#ff6179, #f11133);
}

.mc-analytics-card-group {
  margin-bottom: 10px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 0px;
}
.mc-analytics-card-group .mc-dropdown-toggle i {
  color: var(--lightColor);
}

.mc-analytics-card-content h3 {
  margin-bottom: 3px;
  color: var(--lightColor);
}
.mc-analytics-card-content p {
  font-size: 15px;
  color: var(--lightColor);
  text-transform: capitalize;
}

.mc-countries-card-list {
  height: 364px;
  padding: 0px 15px;
}

.mc-countries-card-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  border-top: 1px solid var(--lightBorder);
  padding: 9px 0px;
}
.mc-countries-card-item div {
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px 15px;
}
.mc-countries-card-item img {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  box-shadow: 0px 3px 10px 0px rgba(var(--blackRGB), 15%);
}
.mc-countries-card-item h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}
.mc-countries-card-item h6 span {
  display: block;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: var(--textColor);
}

.mc-devices-card-head {
  margin-bottom: 20px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}
.mc-devices-card-head h5 {
  line-height: 27px;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
}
.mc-devices-card-head .mc-icon-field {
  margin-bottom: 0px;
  background-color: var(--thinGray);
  border-color: var(--lightBorder);
}

.mc-crm-card {
  gap: 30px;
  display: flex;
  border-radius: 15px;
  background-color: var(--cardBG);
  border: 1px solid var(--lightBorder);
  padding-top: 30px;
  padding-bottom: 15px;
  padding-right: 10px;
  padding-left: 28px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
@media (max-width: 575px) {
  .mc-crm-card {
    padding-top: 30px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
    flex-wrap: wrap;
    gap: 0px;
  }
}
.mc-crm-card:hover {
  box-shadow: var(--cardBoxShadow);
}

.mc-crm-card-group {
  width: 250px;
}
@media (max-width: 575px) {
  .mc-crm-card-group {
    margin-left: 20px;
  }
}
.mc-crm-card-group i {
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 25px;
  font-size: 20px;
  color: var(--lightColor);
}
.mc-crm-card-group i.red {
  background: linear-gradient(#f11133, #ff6179);
  box-shadow: 0px 7px 13px 5px rgba(241, 17, 51, 0.15);
}
.mc-crm-card-group i.blue {
  background: linear-gradient(#2b77e5, #64b3f6);
  box-shadow: 0px 7px 13px 5px rgba(43, 119, 229, 0.15);
}
.mc-crm-card-group i.green {
  background: linear-gradient(#1a9f53, #4eda89);
  box-shadow: 0px 7px 13px 5px rgba(26, 159, 83, 0.15);
}
.mc-crm-card-group i.orange {
  background: linear-gradient(#ff7300, #ffa732);
  box-shadow: 0px 7px 13px 5px rgba(255, 115, 0, 0.15);
}
.mc-crm-card-group i.purple {
  background: linear-gradient(#be0ee1, #ed68ff);
  box-shadow: 0px 7px 13px 5px rgba(190, 14, 225, 0.15);
}
.mc-crm-card-group h3 {
  font-size: 24px;
  white-space: nowrap;
}
.mc-crm-card-group p {
  font-size: 15px;
  white-space: nowrap;
  text-transform: capitalize;
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .mc-crm-card-group p {
    margin-bottom: 10px;
  }
}
.mc-crm-card-group .arrow_upward::before {
  content: "\e5d8";
  color: #27bf68;
}
.mc-crm-card-group .arrow_upward small:first-child {
  color: #27bf68;
}
.mc-crm-card-group .arrow_downward::before {
  content: "\e5db";
  color: #ff304f;
}
.mc-crm-card-group .arrow_downward small:first-child {
  color: #ff304f;
}
.mc-crm-card-group span {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1px;
}
.mc-crm-card-group span small:first-child {
  font-weight: 500;
}
.mc-crm-card-group span small:last-child {
  margin-left: 5px;
}
.mc-crm-card-group span::before {
  font-weight: 600;
  font-family: "Material Icons";
}

.mc-tab-card {
  margin-bottom: 60px;
}

.mc-tab-card-title {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 15px;
  font-weight: 600;
  margin-bottom: 30px;
  white-space: nowrap;
  text-transform: capitalize;
}
.mc-tab-card-title::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--lightBorder);
}

.mc-float-card {
  position: relative;
  border-radius: 8px;
  filter: drop-shadow(0px 7px 12px rgba(var(--blackRGB), 10%));
}
.mc-float-card.sm {
  padding: 20px;
}
.mc-float-card.sm h3 {
  font-size: 22px;
  margin-bottom: 4px;
}
.mc-float-card.sm p {
  font-size: 14px;
}
.mc-float-card.md {
  padding: 24px;
}
.mc-float-card.md h3 {
  font-size: 24px;
  margin-bottom: 6px;
}
.mc-float-card.md p {
  font-size: 15px;
}
.mc-float-card.lg {
  padding: 28px;
}
.mc-float-card.lg h3 {
  font-size: 26px;
  margin-bottom: 8px;
}
.mc-float-card.lg p {
  font-size: 16px;
}
.mc-float-card i {
  position: absolute;
  top: -18px;
  right: 5px;
  z-index: 1;
  filter: drop-shadow(3px 5px 0px rgba(var(--blackRGB), 7%));
  font-size: 50px;
}
.mc-float-card h3 {
  text-transform: capitalize;
  color: var(--lightColor);
}
.mc-float-card p {
  text-transform: capitalize;
  color: var(--lightColor);
}
.mc-float-card.purple {
  background: linear-gradient(#ed68ff, #be0ee1);
}
.mc-float-card.purple i {
  color: #f3a0ff;
}
.mc-float-card.yellow {
  background: linear-gradient(#f4d02b, #e1940e);
}
.mc-float-card.yellow i {
  color: #f6e053;
}
.mc-float-card.green {
  background: linear-gradient(#4eda89, #1a9f53);
}
.mc-float-card.green i {
  color: #89ecb3;
}
.mc-float-card.blue {
  background: linear-gradient(#64b3f6, #2b77e5);
}
.mc-float-card.blue i {
  color: #96cefa;
}
.mc-float-card.red {
  background: linear-gradient(#ff6179, #f11133);
}
.mc-float-card.red i {
  color: #ff9baa;
}

.mc-activity-card-list {
  height: 404px;
  padding-top: 15px;
  padding-bottom: 0px;
  padding-right: 15px;
  padding-left: 15px;
}

.mc-activity-card-item {
  position: relative;
  padding-left: 25px;
  border-left: 2px dashed var(--deepBorder);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-activity-card-item:hover {
  border-color: var(--primaryHEX);
}
.mc-activity-card-item:hover::before {
  background-color: var(--primaryHEX);
}
.mc-activity-card-item::before {
  content: "";
  position: absolute;
  top: 0px;
  left: -10px;
  z-index: 1;
  width: 18px;
  height: 18px;
  line-height: inherit;
  border-radius: 50%;
  text-align: center;
  border: 4px solid var(--lightBorder);
  background-color: var(--textColor);
  box-shadow: 0px 3px 10px 0px rgba(var(--blackRGB), 15%);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.mc-activity-card-title {
  display: flex;
  gap: 10px;
}
@media (max-width: 575px) {
  .mc-activity-card-title {
    flex-wrap: wrap;
  }
}
.mc-activity-card-title h6 {
  font-size: 14px;
  text-transform: inherit;
}
.mc-activity-card-title small {
  font-size: 12px;
  white-space: nowrap;
}

.mc-activity-card-body {
  padding: 12px 0px 28px;
  font-size: 15px;
}
.mc-activity-card-body .mc-btn {
  margin-top: 20px;
}

.mc-activity-card-text {
  font-size: 14px;
}

.mc-activity-card-media {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}
.mc-activity-card-media img {
  width: 26px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 8px;
  box-shadow: 0px 3px 10px 0px rgba(var(--blackRGB), 15%);
}
.mc-activity-card-media h6 {
  font-size: 13px;
  text-transform: capitalize;
}

.mc-order-card-list {
  margin-top: 30px;
}

.mc-order-card-item {
  padding: 12px 0px;
  border-top: 1px solid var(--lightBorder);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.mc-order-card-item:last-child {
  padding-bottom: 0px;
}
.mc-order-card-item i {
  font-size: 24px;
  color: var(--primaryHEX);
  text-shadow: 0px 5px 7px rgba(0, 0, 0, 0.1);
}
.mc-order-card-item i.red {
  background: -webkit-linear-gradient(#f11133, #ff6179);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mc-order-card-item i.blue {
  background: -webkit-linear-gradient(#2b77e5, #64b3f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mc-order-card-item i.green {
  background: -webkit-linear-gradient(#1a9f53, #4eda89);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mc-order-card-item i.purple {
  background: -webkit-linear-gradient(#be0ee1, #ed68ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mc-order-card-item i.yellow {
  background: -webkit-linear-gradient(#e1940e, #f4d02b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mc-order-card-item p {
  flex: auto;
  font-size: 15px;
  text-transform: capitalize;
}
.mc-order-card-item h5 {
  font-size: 15px;
}

.mc-overview-card {
  transform: translateY(0);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-overview-card:hover {
  transform: translateY(-5px);
}
.mc-overview-card:hover .mc-overview-overlay {
  opacity: 1;
  visibility: visible;
}
.mc-overview-card:hover .mc-overview-link {
  top: 50%;
}

.mc-overview-media {
  position: relative;
  margin-bottom: 22px;
}
.mc-overview-media img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 15px 35px 0px rgba(var(--blackRGB), 8%);
}

.mc-overview-label {
  position: absolute;
  top: -25px;
  z-index: 1;
  font-size: 26px;
  font-weight: 700;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  text-align: center;
  border: 8px solid var(--lightColor);
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: var(--lightColor);
  background: var(--primaryHEX);
  box-shadow: 0px 8px 30px 0px rgba(var(--blackRGB), 20%);
}
.mc-overview-label.left {
  left: -25px;
}
.mc-overview-label.right {
  right: -25px;
}
.mc-overview-label span {
  display: block;
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  top: 70%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.mc-overview-badge {
  position: absolute;
  top: -20px;
  right: -20px;
  z-index: 1;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  color: var(--lightColor);
  background-color: #ff304f;
  box-shadow: 0px 8px 30px 0px rgba(var(--blackRGB), 20%);
}

.mc-overview-overlay {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: rgba(var(--blackRGB), 70%);
  visibility: hidden;
  opacity: 0;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.mc-overview-link {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 1;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 32px;
  border-radius: 8px;
  letter-spacing: 0.5px;
  white-space: nowrap;
  text-transform: uppercase;
  color: var(--lightColor);
  background: var(--primaryHEX);
  transform: translate(-50%, -50%);
  box-shadow: var(--btnBoxShadow);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-overview-link:hover {
  color: var(--lightColor);
}

.mc-overview-name {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.5px;
  color: #64b3f6;
}

.mc-header {
  width: 100%;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5;
}
@media (max-width: 575px) {
  .mc-header {
    padding: 12px 15px;
  }
}
.mc-header.sticky {
  backdrop-filter: blur(5px);
  background-color: rgba(var(--lightRGB), 90%);
}
.mc-header img {
  border-radius: 5px;
}

.mc-logo-group {
  margin-right: 105px;
}
@media (max-width: 1199px) {
  .mc-logo-group {
    margin: 0px;
  }
}
@media (max-width: 575px) {
  .mc-logo-group img {
    height: 38px;
  }
}
@media (max-width: 575px) {
  .mc-logo-group span {
    font-size: 22px;
  }
}
@media (min-width: 0px) and (max-width: 450px) {
  .mc-logo-group span {
    display: none;
  }
}

.mc-header-group {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
}
@media (max-width: 1199px) {
  .mc-header-group {
    justify-content: end;
  }
}
@media (max-width: 767px) {
  .mc-header-group {
    gap: 15px;
  }
}
@media (max-width: 575px) {
  .mc-header-group {
    gap: 10px;
  }
}

.mc-header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;
}
@media (max-width: 767px) {
  .mc-header-left {
    gap: 15px;
  }
}
@media (max-width: 575px) {
  .mc-header-left {
    gap: 10px;
  }
}
.mc-header-left .mc-header-icon.search {
  display: none;
}
@media (max-width: 991px) {
  .mc-header-left .mc-header-icon.search {
    display: block;
  }
}
.mc-header-left .mc-header-icon.toggle {
  margin-right: 32px;
}
@media (max-width: 1199px) {
  .mc-header-left .mc-header-icon.toggle {
    margin: 0px;
  }
}
.mc-header-left .mc-header-icon.toggle i {
  font-size: 22px;
}
@media (max-width: 575px) {
  .mc-header-left .mc-header-icon.toggle i {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .mc-header-search-group {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--blackRGB), 90%);
    visibility: hidden;
    opacity: 0;
    transition: all linear 0.3s;
    -webkit-transition: all linear 0.3s;
    -moz-transition: all linear 0.3s;
    -ms-transition: all linear 0.3s;
    -o-transition: all linear 0.3s;
  }
}
.mc-header-search-group.show {
  visibility: visible;
  opacity: 1;
}

.mc-header-search {
  width: 280px;
  height: 40px;
  border-radius: 8px;
  background-color: rgba(var(--primaryRGB), 6%);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
@media (max-width: 991px) {
  .mc-header-search {
    width: 100%;
    height: 60px;
    max-width: 350px;
    margin: 0px 25px;
    background-color: var(--lightBG);
  }
  .mc-header-search:hover {
    background-color: var(--lightBG);
  }
  .mc-header-search:focus-within {
    background-color: var(--lightBG);
  }
}
.mc-header-search:hover {
  background-color: rgba(var(--primaryRGB), 10%);
}
.mc-header-search:hover button {
  color: #55DFB2;
}
.mc-header-search:hover input::placeholder {
  color: #55DFB2;
}
.mc-header-search button {
  margin-left: 12px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-header-search input {
  width: 100%;
  height: 100%;
  padding: 0px 12px;
}

.mc-header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0px 18px;
}
@media (max-width: 1199px) {
  .mc-header-right .cart,
  .mc-header-right .message,
  .mc-header-right .notify {
    display: none;
  }
}
@media (max-width: 767px) {
  .mc-header-right {
    gap: 15px;
  }
}
@media (max-width: 575px) {
  .mc-header-right {
    gap: 10px;
  }
}
.mc-header-right .mc-dropdown-paper {
  margin-top: 15px;
}

.mc-header-icon {
  position: relative;
}
.mc-header-icon i {
  font-size: 18px;
  width: 38px;
  height: 38px;
  line-height: 38px;
  border-radius: 50%;
  text-align: center;
  color: var(--textColor);
  background-color: rgba(var(--primaryRGB), 6%);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
@media (max-width: 575px) {
  .mc-header-icon i {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
  }
}
.mc-header-icon i:hover {
  color: #55DFB2;
  background-color: rgba(var(--primaryRGB), 10%);
}
.mc-header-icon sup {
  position: absolute;
  top: -12px;
  right: -8px;
  z-index: 1;
  font-size: 10px;
  font-weight: 500;
  padding: 8px 4px 9px;
  border-radius: 15px;
  color: #120144;
  border: 3px solid var(--lightBorder);
}
.mc-header-icon sup.red {
  background: #ff304f;
}
.mc-header-icon sup.blue {
  background: #4094f1;
}
.mc-header-icon sup.green {
  background: #27bf68;
}
.mc-header-icon sup.primary {
  background: #55DFB2;
}

.mc-header-language {
  width: 180px;
  padding: 12px 13px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
  position: relative;
}
.mc-header-language:hover {
  background-color: var(--thinGray);
}
.mc-header-language.active {
  color: var(--activeColor);
  background-color: var(--activeBG);
}
.mc-header-language.active::before {
  content: "\e5ca";
  font-weight: 900;
  color: var(--activeColor);
  font-family: "Material Icons";
  position: absolute;
  top: 50%;
  right: 13px;
  z-index: 1;
  transform: translateY(-50%);
}
.mc-header-language img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 3px 10px 0px rgba(var(--blackRGB), 15%);
}
.mc-header-language span {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.mc-header-dropdown-group {
  width: 280px;
}
.mc-header-dropdown-group .mc-card-header {
  margin-bottom: 0px;
  padding: 5px 8px 8px;
  border-bottom: 1px solid var(--lightBorder);
}
.mc-header-dropdown-group .mc-card-title {
  font-size: 16px;
  font-weight: 700;
}
.mc-header-dropdown-group .mc-dropdown-toggle i {
  font-size: 20px;
  color: var(--textColor);
}

.mc-header-dropdown-list {
  height: 300px;
}

.mc-header-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  padding: 10px 10px;
  margin: 2px 0px;
  border-radius: 8px;
  border-bottom: 1px solid var(--lightBorder);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-header-dropdown-item:hover {
  background-color: var(--hoverBG);
}
.mc-header-dropdown-item.active {
  background-color: var(--activeBG);
}
.mc-header-dropdown-item.active .mc-header-dropdown-shop-media img,
.mc-header-dropdown-item.active .mc-header-dropdown-shop-media span {
  border-color: rgba(var(--primaryRGB), 8%);
}
.mc-header-dropdown-item.active .mc-header-dropdown-meta h4 cite {
  font-weight: 700;
}
.mc-header-dropdown-item.active .mc-header-dropdown-meta h4 span {
  font-weight: 500;
}
.mc-header-dropdown-item.active .mc-header-dropdown-meta h4 span b {
  font-weight: 700;
}
.mc-header-dropdown-item.active .mc-header-dropdown-meta p {
  font-weight: 600;
  color: var(--activeColor);
}
.mc-header-dropdown-item .mc-dropdown-toggle i {
  font-size: 18px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-header-dropdown-item .mc-dropdown-toggle i:hover {
  color: var(--primaryHEX);
}
.mc-header-dropdown-item sup {
  border-radius: 15px;
  padding: 8px 4px 9px;
  font-size: 10px;
  font-weight: 500;
  color: var(--lightColor);
  background-color: var(--primaryHEX);
}

.mc-header-dropdown-content {
  width: 100%;
  display: flex;
  gap: 12px;
}
.mc-header-dropdown-content:hover {
  color: var(--textColor);
}

.mc-header-dropdown-meta {
  width: 140px;
  overflow: hidden;
  flex-grow: 1;
}
.mc-header-dropdown-meta h4 {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px;
  margin-bottom: 3px;
}
.mc-header-dropdown-meta h4 cite {
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mc-header-dropdown-meta h4 span {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}
.mc-header-dropdown-meta h4 span b {
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
}
.mc-header-dropdown-meta h4 time {
  font-size: 11px;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--textColor);
}
.mc-header-dropdown-meta h4 time::before {
  content: "~";
  margin: 0px 3px;
}
.mc-header-dropdown-meta p {
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dark_mode .mc-header-dropdown-group .mc-card-title {
  font-weight: 600;
}
.dark_mode .mc-header-dropdown-meta h4 cite {
  font-weight: 400;
}
.dark_mode .mc-header-dropdown-meta h4 span {
  font-weight: 200;
}
.dark_mode .mc-header-dropdown-meta h4 span b {
  font-weight: 400;
}
.dark_mode .mc-header-dropdown-item.active .mc-header-dropdown-meta h4 span {
  font-weight: 300;
}
.dark_mode .mc-header-dropdown-item.active .mc-header-dropdown-meta h4 cite,
.dark_mode .mc-header-dropdown-item.active .mc-header-dropdown-meta h4 span b,
.dark_mode .mc-header-dropdown-item.active .mc-header-dropdown-meta p {
  font-weight: 500;
}

.mc-header-dropdown-button {
  width: 100%;
  font-size: 11px;
  padding: 8px 10px;
  text-align: center;
}

.mc-header-dropdown-shop-media {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px;
  align-self: flex-start;
}
.mc-header-dropdown-shop-media img,
.mc-header-dropdown-shop-media span {
  border-width: 2px;
  border-style: solid;
  border-color: var(--lightBorder);
  margin-left: -20px;
}
.mc-header-dropdown-shop-media img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.mc-header-dropdown-shop-media img:first-child {
  margin: 0px;
}
.mc-header-dropdown-shop-media span {
  width: 40px;
  height: 40px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--lightBG);
  display: inline-block;
}

.mc-header-dropdown-message-media .mc-round-avatar {
  position: relative;
}
.mc-header-dropdown-message-media .mc-round-avatar::before {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: auto;
  width: 13px;
  height: 13px;
  line-height: 13px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid var(--lightBorder);
  background-color: #9a9a9a;
  content: "";
}
.mc-header-dropdown-message-media .mc-round-avatar.online::before {
  background-color: #27bf68;
}

.mc-header-dropdown-notify-media {
  position: relative;
}
.mc-header-dropdown-notify-media img {
  width: 40px;
  border-radius: 50%;
}
.mc-header-dropdown-notify-media i {
  width: 25px;
  height: 25px;
  line-height: 21px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid var(--lightBorder);
  color: var(--lightColor);
  font-size: 13px;
  position: absolute;
  top: 20px;
  right: -5px;
}
.mc-header-dropdown-notify-media i.red {
  background: rgba(var(--redRGB), 75%);
}
.mc-header-dropdown-notify-media i.yellow {
  background: rgba(var(--yellowRGB), 75%);
}
.mc-header-dropdown-notify-media i.green {
  background: rgba(var(--greenRGB), 75%);
}
.mc-header-dropdown-notify-media i.blue {
  background: rgba(var(--blueRGB), 75%);
}
.mc-header-dropdown-notify-media i.purple {
  background: rgba(var(--purpleRGB), 75%);
}
.mc-header-dropdown-notify-media i.primary {
  background: rgba(var(--primaryRGB), 75%);
}

.mc-header-user {
  position: relative;
}
.mc-header-user .mc-dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px 7px;
}
.mc-header-user .mc-dropdown-toggle.dropdown-toggle::after {
  content: "";
  display: inherit;
  margin-left: inherit;
  vertical-align: inherit;
  align-self: flex-start;
  margin-top: 7px;
  border-radius: 5px;
  border-bottom: 0;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
@media (max-width: 575px) {
  .mc-header-user .mc-dropdown-toggle.dropdown-toggle::after {
    display: none;
  }
}
@media (max-width: 575px) {
  .mc-header-user .mc-dropdown-toggle .mc-round-avatar.xs img {
    width: 34px;
    height: 34px;
  }
}
@media (max-width: 575px) {
  .mc-header-user .mc-dropdown-toggle .mc-duel-text {
    display: none;
  }
}
.mc-header-user .red {
  color: #ff304f;
}
.mc-header-user .mc-dropdown-paper {
  margin: 15px;
}
.mc-header-user .mc-dropdown-menu {
  padding: 6px 10px;
  border-radius: 8px;
  gap: 10px;
}
.mc-header-user .mc-dropdown-menu:hover {
  color: var(--textColor);
  background-color: var(--hoverBG);
}
.mc-header-user .mc-dropdown-menu i {
  font-size: 20px;
}
.mc-header-user .mc-dropdown-menu span {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
}

.mc-sidebar {
  position: fixed;
  top: 75px;
  left: -270px;
  z-index: 3;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 17px;
  padding-left: 20px;
  width: 270px;
  overflow-y: scroll;
  height: calc(100vh - 75px);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
@media (max-width: 991px) {
  .mc-sidebar {
    z-index: 5;
    background-color: var(--bodyBG);
    border-right: 1px solid var(--lightGray);
  }
}
@media (max-width: 575px) {
  .mc-sidebar {
    top: 62px;
    height: calc(100vh - 62px);
  }
}
.mc-sidebar.active {
  left: 0px;
}

.mc-sidebar-menu {
  margin-bottom: 25px;
}
.mc-sidebar-menu h4 {
  width: 100%;
  padding: 10px 4px;
  background-color: #120144;
  margin-bottom: 10px;
  border-radius: 5px;
  color: white;
}

.mc-sidebar-menu-title {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 15px;
  color: #b4b4b4;
}

.mc-sidebar-menu-item {
  margin-bottom: 3px;
}
.mc-sidebar-menu-item.focused .mc-sidebar-menu-btn {
  color: #55DFB2;
  background-color: var(--activeBG);
}
.mc-sidebar-menu-item.active .mc-sidebar-menu-btn {
  color: #55DFB2;
  background-color: var(--activeBG);
}
.mc-sidebar-menu-item.active .mc-sidebar-menu-btn small {
  transform: rotate(0deg);
}
.mc-sidebar-menu-item.active .mc-sidebar-dropdown-list {
  height: auto;
  overflow: visible;
  padding: 10px 0px;
}

.mc-sidebar-menu-btn {
  width: 100%;
  padding: 12px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px 12px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-sidebar-menu-btn:hover {
  background-color: var(--hoverBG);
}
.mc-sidebar-menu-btn i {
  font-size: 22px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-sidebar-menu-btn span {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  text-align: left;
  flex-grow: 1;
}
.mc-sidebar-menu-btn sup {
  line-height: 18px;
  letter-spacing: 0.3px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: #120144;
}
.mc-sidebar-menu-btn sup.square {
  padding: 0px 5px 1px;
  border-radius: 4px;
}
.mc-sidebar-menu-btn sup.round {
  border-radius: 15px;
  padding: 0px 4px 1px;
}
.mc-sidebar-menu-btn sup.red {
  background: #ff304f;
}
.mc-sidebar-menu-btn sup.blue {
  background: #4094f1;
}
.mc-sidebar-menu-btn sup.green {
  background: #27bf68;
}
.mc-sidebar-menu-btn sup.purple {
  background: #de2fff;
}
.mc-sidebar-menu-btn sup.yellow {
  background: #edb213;
}
.mc-sidebar-menu-btn sup.primary {
  background: #55DFB2;
}
.mc-sidebar-menu-btn small {
  font-size: 20px;
  transform: rotate(-90deg);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.mc-sidebar-dropdown-list {
  height: 0px;
  overflow: hidden;
  margin: 0px 20px;
  border-left: 1px solid var(--deepBorder);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.mc-sidebar-dropdown-item.active .mc-sidebar-dropdown-link {
  color: var(--primaryHEX);
}
.mc-sidebar-dropdown-item.active .mc-sidebar-dropdown-link::before {
  opacity: 1;
}

.mc-sidebar-dropdown-link {
  width: 100%;
  font-size: 15px;
  position: relative;
  text-transform: capitalize;
  padding-left: 20px;
  margin: 6px 0px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-sidebar-dropdown-link:hover {
  color: var(--primaryHEX);
}
.mc-sidebar-dropdown-link::before {
  content: "";
  position: absolute;
  top: 0px;
  left: -1px;
  z-index: 1;
  width: 2px;
  height: 100%;
  opacity: 0;
  background-color: var(--primaryHEX);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-sidebar-dropdown-link:hover::before {
  opacity: 1;
}

.mc-sidebar-logout {
  padding: 45px;
  border-radius: 8px;
  background: linear-gradient(rgba(85, 223, 178, 0.25), rgba(85, 223, 178, 0.4));
  position: relative;
  overflow: hidden;
}
.mc-sidebar-logout::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  background: linear-gradient(rgba(39, 204, 202, 0.25), rgba(39, 204, 202, 0.4));
}
.mc-sidebar-logout::after {
  content: "";
  position: absolute;
  top: -40px;
  right: -10px;
  z-index: 1;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
  text-align: center;
  background: linear-gradient(rgba(39, 204, 202, 0.25), rgba(39, 204, 202, 0.4));
}
.mc-sidebar-logout .mc-btn {
  margin: 0 auto;
  padding: 12px 18px 12px 3px;
}

.mc-main {
  padding: 24px;
  border-radius: 8px;
  background-color: var(--thinGray);
  margin-top: 75px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
@media (max-width: 575px) {
  .mc-main {
    padding: 24px 15px;
    margin-top: 62px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
}
.mc-main.active {
  margin-left: 270px;
}
@media (max-width: 991px) {
  .mc-main.active {
    margin-left: 20px;
  }
}
@media (max-width: 575px) {
  .mc-main.active {
    margin-left: 0px;
  }
}

.mc-footer {
  width: 100%;
  font-size: 14px;
  margin-top: 24px;
  text-align: center;
}
@media (max-width: 575px) {
  .mc-footer {
    margin-bottom: 20px;
  }
}

.mc-review-analytics-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  flex-wrap: wrap;
}
@media (max-width: 575px) {
  .mc-review-analytics-group {
    gap: 50px;
  }
}

.mc-review-analytics-graph-group {
  width: 350px;
  flex-shrink: 0;
}
@media (max-width: 575px) {
  .mc-review-analytics-graph-group {
    width: 100%;
  }
}

.mc-review-analytics-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.mc-review-analytics-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.mc-review-analytics-graph {
  width: 100%;
  border-radius: 6px;
  background: var(--deepGray);
}
.mc-review-analytics-graph span {
  height: 6px;
  display: block;
  border-radius: 6px;
  background-color: #edb213;
}

.mc-review-analytics-count,
.mc-review-analytics-user {
  white-space: nowrap;
  text-transform: capitalize;
  letter-spacing: 0.3px;
  font-size: 15px;
}

.mc-review-analytics-detail-group {
  text-align: center;
}

.mc-review-analytics-total {
  color: var(--textColor);
  margin-bottom: 20px;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 500;
}

.mc-review-analytics-score {
  font-size: 80px;
  font-weight: 800;
  line-height: 60px;
  letter-spacing: -1px;
  margin-bottom: 20px;
}

.mc-review-analytics-star {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.mc-review-analytics-star i {
  font-size: 22px;
  color: #f0f0f0;
}
.mc-review-analytics-star i.active {
  color: #edb213;
}

.mc-review-analytics-text {
  text-transform: capitalize;
  font-size: 16px;
}

.mc-review-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.mc-review-item ul {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 30px;
}
.mc-review-item ul li {
  margin-left: 60px;
}
@media (max-width: 575px) {
  .mc-review-item ul li {
    margin-left: 20px;
  }
}

.mc-review-group {
  display: flex;
  gap: 25px;
}
@media (max-width: 575px) {
  .mc-review-group {
    gap: 0px;
  }
}

.mc-review-data {
  padding: 35px;
  border-radius: 15px;
  background-color: var(--thinGray);
}
@media (max-width: 575px) {
  .mc-review-data {
    padding: 15px;
  }
}

.mc-review-head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.mc-review-user {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 6px 12px;
  flex-wrap: wrap;
  position: relative;
}

.mc-review-admin {
  position: absolute;
  top: 46px;
  left: 4px;
  z-index: 1;
  font-size: 10px;
  padding: 4px 7px;
  border-radius: 5px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  display: inline-block;
  color: var(--lightColor);
  background-color: #5a5a5a;
}
@media (max-width: 575px) {
  .mc-review-admin {
    top: -16px;
    left: 4px;
  }
}

.mc-review-reply .mc-btn {
  padding: 9px 12px;
}

.mc-review-star {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
}
.mc-review-star i {
  font-size: 22px;
  color: #edb213;
}

.mc-review-dots .mc-dropdown-toggle i {
  color: var(--textColor);
  background-color: var(--lightGray);
  font-size: 20px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-review-dots .mc-dropdown-toggle i:hover {
  color: var(--lightColor);
  background: var(--primary);
}

.mc-review-form-btn {
  width: 100%;
  font-size: 14px;
  margin-top: 25px;
  padding: 24px 0px;
}

.mc-auth {
  position: relative;
  height: 100vh;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mc-auth-pattern {
  padding: 0;
  margin: 0;
  width: 104%;
  height: 104%;
  position: absolute;
  opacity: 0.8;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  animation: cambioImagen ease-in-out infinite 120s;
}

.scroll::-webkit-scrollbar {
  width: 8px;
  background-color: var(--bodyBG);
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--bodyBG);
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--bodyBG);
  background-color: var(--primaryGreen);
}

@keyframes cambioImagen {
  0% {
    background-image: url(../images/fondos/fondo1.jpg);
  }
  5% {
    background-image: url(../images/fondos/fondo2.jpg);
  }
  10% {
    background-image: url(../images/fondos/fondo3.jpg);
  }
  15% {
    background-image: url(../images/fondos/fondo4.jpg);
  }
  20% {
    background-image: url(../images/fondos/fondo5.jpg);
  }
  25% {
    background-image: url(../images/fondos/fondo6.jpg);
  }
  30% {
    background-image: url(../images/fondos/fondo7.jpg);
  }
  35% {
    background-image: url(../images/fondos/fondo8.jpg);
  }
  40% {
    background-image: url(../images/fondos/fondo1.jpg);
  }
  45% {
    background-image: url(../images/fondos/fondo2.jpg);
  }
  50% {
    background-image: url(../images/fondos/fondo3.jpg);
  }
  55% {
    background-image: url(../images/fondos/fondo4.jpg);
  }
  60% {
    background-image: url(../images/fondos/fondo5.jpg);
  }
  65% {
    background-image: url(../images/fondos/fondo6.jpg);
  }
  70% {
    background-image: url(../images/fondos/fondo7.jpg);
  }
  75% {
    background-image: url(../images/fondos/fondo8.jpg);
  }
  80% {
    background-image: url(../images/fondos/fondo1.jpg);
  }
  85% {
    background-image: url(../images/fondos/fondo2.jpg);
  }
  90% {
    background-image: url(../images/fondos/fondo3.jpg);
  }
  100% {
    background-image: url(../images/fondos/fondo4.jpg);
  }
}
.mc-auth-group {
  max-width: 360px;
  margin: 0px auto;
  padding: 30px 0px 50px;
  text-align: center;
}
@media (max-width: 575px) {
  .mc-auth-group {
    padding: 30px 15px 50px;
  }
}

.mc-auth-logo {
  margin-bottom: 10px;
}
.mc-auth-logo img {
  height: 55px;
  border-radius: 5px;
}

.mc-auth-title {
  margin-bottom: 25px;
  color: #120144;
}

.mc-auth-form {
  padding: 35px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: var(--lightGray);
  border: 1px solid var(--deepBorder);
}
@media (max-width: 575px) {
  .mc-auth-form {
    padding: 25px;
  }
}
.mc-auth-form p {
  color: red;
  padding: 10px 0;
}
.mc-auth-form button {
  position: relative;
}
.mc-auth-form button div {
  position: absolute;
  top: 5px;
  left: 130px;
}

.mc-auth-forgot {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-auth-forgot:hover {
  text-decoration: underline;
  color: var(--primaryHRX);
}

.mc-auth-checkbox {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}
.mc-auth-checkbox input {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-top: 2px;
  cursor: pointer;
}
.mc-auth-checkbox label {
  font-size: 15px;
  text-align: left;
  cursor: pointer;
}

.mc-auth-btn {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 8px;
  margin-bottom: 8px;
  color: var(--lightColor);
  background-color: #120144;
  position: relative;
}
.mc-auth-btn.h-sm {
  height: 42px;
}
.mc-auth-btn.h-md {
  height: 45px;
}
.mc-auth-btn.h-lg {
  height: 48px;
}
.mc-auth-btn.h-xl {
  height: 50px;
}
.mc-auth-btn .spinner {
  position: absolute;
  left: 120px;
}

.mc-auth-divide {
  margin: 15px 0 18px;
  position: relative;
  text-align: center;
  z-index: 1;
}
.mc-auth-divide::after {
  position: absolute;
  content: "";
  z-index: -1;
  top: 17px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--hardBorder);
}
.mc-auth-divide span {
  width: 36px;
  height: 36px;
  line-height: 32px;
  border-radius: 50%;
  text-align: center;
  font-size: 15px;
  display: inline-block;
  color: var(--textColor);
  border: 1px solid var(--hardBorder);
  background-color: var(--lightGray);
}

.mc-auth-connect {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.mc-auth-connect a {
  font-size: 15px;
  border-radius: 8px;
  color: var(--lightColor);
  background-color: var(--primaryHEX);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px 10px;
}
.mc-auth-connect a.instagram {
  background-color: var(--instagram);
}
.mc-auth-connect a.facebook {
  background-color: var(--facebook);
}
.mc-auth-connect a.twitter {
  background-color: var(--twitter);
}
.mc-auth-connect a.google {
  background-color: var(--google);
}
.mc-auth-connect a.apple {
  background-color: var(--apple);
  position: relative;
}
.mc-auth-connect a.h-sm {
  height: 42px;
}
.mc-auth-connect a.h-md {
  height: 45px;
}
.mc-auth-connect a.h-lg {
  height: 48px;
}
.mc-auth-connect a.h-xl {
  height: 50px;
}
.mc-auth-connect a.desactivado {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 70%;
  display: none;
}

.mc-auth-navigate {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 20px;
  font-size: 15px;
  border-radius: 8px;
  background-color: var(--lightGray);
  border: 1px solid var(--deepBorder);
}
.mc-auth-navigate a {
  color: var(--primaryHEX);
  font-weight: 500;
  text-transform: capitalize;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-auth-navigate a:hover {
  text-decoration: underline;
}

.mc-register {
  display: flex;
}

.mc-register-banner {
  width: 65%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  background: linear-gradient(to right, var(--lightGray), var(--thinGray));
}
@media (min-width: 768px) and (max-width: 1199px) {
  .mc-register-banner {
    width: 55%;
  }
}
@media (max-width: 767px) {
  .mc-register-banner {
    display: none;
  }
}

.mc-register-banner-pattern {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  opacity: 0.08;
}

.mc-register-banner-content {
  padding: 30px;
  max-width: 700px;
}

.mc-register-banner-title {
  font-size: 45px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 15px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .mc-register-banner-title {
    font-size: 35px;
  }
}

.mc-register-banner-descrip {
  font-size: 17px;
  margin-bottom: 40px;
}

.mc-register-form {
  width: 35%;
  height: 100%;
  text-align: center;
  padding: 30px 60px 50px;
  background-color: var(--lightGray);
}
@media (min-width: 768px) and (max-width: 1199px) {
  .mc-register-form {
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .mc-register-form {
    padding: 40px 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .mc-register-form {
    padding: 30px 35px;
  }
}
@media (max-width: 767px) {
  .mc-register-form {
    width: 100%;
    padding: 50px 80px;
  }
}
@media (max-width: 575px) {
  .mc-register-form {
    width: 100%;
    padding: 30px;
  }
}

.mc-register-navigate {
  font-size: 15px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.mc-register-navigate a {
  font-weight: 500;
  color: var(--primaryHEX);
  text-transform: capitalize;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-register-navigate a:hover {
  text-decoration: underline;
}

.mc-user-avatar-upload {
  text-align: center;
}

.mc-user-avatar {
  width: fit-content;
  border-radius: 50%;
  border: 2px solid var(--primaryHEX);
  margin: 0px auto 15px;
}
.mc-user-avatar img {
  width: 145px;
  height: 145px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid transparent;
}

.mc-user-group {
  padding: 0px 10px 10px;
}

.mc-user-profile {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px 20px;
  flex-wrap: wrap;
}
.mc-user-profile h4 {
  text-transform: capitalize;
}
.mc-user-profile h4 span {
  font-size: 15px;
  font-weight: 400;
  text-transform: lowercase;
  margin-top: 5px;
  display: block;
}

.mc-user-metalist {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}
.mc-user-metalist li {
  display: flex;
  gap: 15px;
}
.mc-user-metalist li i {
  font-size: 20px;
  color: var(--primaryHEX);
}
.mc-user-metalist li span {
  font-size: 15px;
}

.mc-user-bio {
  font-size: 15px;
}

.mc-user-social {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.mc-user-social a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 8px;
  padding: 8px 15px;
  color: var(--lightColor);
  background-color: var(--lightGray);
}
.mc-user-social a i {
  font-size: 15px;
}
.mc-user-social a span {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.mc-user-social a.facebook {
  background-color: var(--facebook);
}
.mc-user-social a.twitter {
  background-color: var(--twitter);
}
.mc-user-social a.linkedin {
  background-color: var(--linkedin);
}
.mc-user-social a.youtube {
  background-color: var(--youtube);
}
.mc-user-social a.whatsapp {
  background-color: var(--whatsapp);
}

.mc-user-activity-item {
  position: relative;
  margin: 15px 0px;
  padding-left: 20px;
  border-left: 1px solid var(--deepBorder);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-user-activity-item:hover {
  border-color: var(--primaryHEX);
}
.mc-user-activity-item:hover::before {
  background-color: var(--primaryHEX);
}
.mc-user-activity-item::before {
  content: "";
  position: absolute;
  top: 0px;
  left: -10px;
  z-index: 1;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid var(--lightColor);
  background-color: #b4b4b4;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.mc-user-activity-title {
  display: flex;
  gap: 10px;
}
.mc-user-activity-title h6 {
  font-size: 14px;
  text-transform: inherit;
}
.mc-user-activity-title small {
  font-size: 13px;
  white-space: nowrap;
}

.mc-user-activity-body {
  padding: 15px 0px;
  font-size: 15px;
}
.mc-user-activity-body .mc-btn {
  margin-top: 20px;
}

.mc-user-activity-media {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}
.mc-user-activity-media img {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 8px;
  box-shadow: 0px 3px 10px 0px rgba(var(--blackRGB), 15%);
}
.mc-user-activity-media h6 {
  font-size: 14px;
}

.mc-product-view-gallery {
  display: grid;
  padding: 10px;
  grid-gap: 20px;
  grid-template-columns: 12;
  grid-template-rows: auto;
}
.mc-product-view-gallery img {
  width: 100%;
  border-radius: 8px;
  border: 2px dashed var(--deepBorder);
  filter: drop-shadow(0px 10px 15px rgba(var(--blackRGB), 5%));
}
.mc-product-view-gallery img:nth-child(1) {
  grid-column: 1/13;
}
.mc-product-view-gallery img:nth-child(2) {
  grid-column: 1/4;
}
.mc-product-view-gallery img:nth-child(3) {
  grid-column: 4/7;
}
.mc-product-view-gallery img:nth-child(4) {
  grid-column: 7/10;
}
.mc-product-view-gallery img:nth-child(5) {
  grid-column: 10/13;
}

.mc-product-view-info-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
}

.mc-product-view-info-title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 30px;
}

.mc-product-view-meta {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 0px;
}
.mc-product-view-meta i {
  font-size: 20px;
  margin-right: 12px;
}
.mc-product-view-meta h5 {
  width: 90px;
  font-size: 15px;
  text-transform: capitalize;
}
.mc-product-view-meta span {
  line-height: 16px;
  margin-right: 20px;
}
.mc-product-view-meta p {
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
}
.mc-product-view-meta p del {
  margin-left: 3px;
  color: #ff304f;
}
.mc-product-view-meta ul {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  flex-wrap: wrap;
}
.mc-product-view-meta ul li {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 4px 8px;
  background-color: var(--lightGray);
}

.mc-product-view-descrip {
  padding: 0px 10px 10px;
}
.mc-product-view-descrip p {
  font-size: 15px;
  line-height: 30px;
}

.mc-product-upload-organize {
  display: flex;
  align-items: end;
  justify-content: start;
  gap: 15px;
}
.mc-product-upload-organize .mc-label-field-group {
  width: 100%;
}
.mc-product-upload-organize .mc-btn {
  width: 90px;
  height: 42px;
  flex-shrink: 0;
  padding: 8px 12px;
}

.mc-product-upload-media {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-template-rows: auto;
}
@media (max-width: 575px) {
  .mc-product-upload-media {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(115px, 1fr));
    grid-template-rows: auto;
  }
}

.mc-product-upload-image {
  cursor: pointer;
  position: relative;
}
.mc-product-upload-image:hover::after {
  opacity: 1;
  color: var(--primaryHEX);
}
.mc-product-upload-image:hover img {
  border-color: var(--primaryHEX);
}
.mc-product-upload-image.active::after {
  opacity: 1;
}
.mc-product-upload-image.active img {
  border-color: #27bf68;
}
.mc-product-upload-image::after {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
  font-family: "Material Icons";
  line-height: 22px;
  font-size: 22px;
  content: "\e86c";
  opacity: 0;
  color: #27bf68;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-product-upload-image img {
  width: 100%;
  border-radius: 8px;
  border: 2px dashed var(--deepBorder);
  filter: drop-shadow(0px 10px 15px rgba(var(--blackRGB), 5%));
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.mc-product-upload-file {
  position: relative;
  border-radius: 8px;
  border: 2px dashed var(--deepBorder);
  filter: drop-shadow(0px 10px 15px rgba(var(--blackRGB), 5%));
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-product-upload-file:hover {
  border-color: #b4b4b4;
}
.mc-product-upload-file input {
  width: 100%;
  height: 100%;
  visibility: hidden;
  position: absolute;
}
.mc-product-upload-file label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  flex-direction: column;
  padding: 20px 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
}
.mc-product-upload-file label i {
  font-size: 90px;
  opacity: 0.1;
}
@media (max-width: 575px) {
  .mc-product-upload-file label i {
    font-size: 60px;
  }
}
.mc-product-upload-file label p {
  width: 100px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mc-product-upload-btn {
  width: 100%;
  margin-top: 50px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
  gap: 10px;
}
.mc-product-upload-btn i {
  font-size: 20px;
}

.mc-invoice-head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .mc-invoice-head {
    align-items: start;
  }
}
.mc-invoice-head img {
  height: 60px;
}
.mc-invoice-head h2 {
  font-size: 22px;
  font-weight: 800;
  text-transform: uppercase;
}

.mc-invoice-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .mc-invoice-group {
    flex-wrap: wrap;
  }
}

.mc-invoice-recieved,
.mc-invoice-shipment {
  max-width: 250px;
}
@media (max-width: 575px) {
  .mc-invoice-recieved,
  .mc-invoice-shipment {
    max-width: 100%;
  }
}
.mc-invoice-recieved h6,
.mc-invoice-shipment h6 {
  font-weight: 600;
  margin-bottom: 8px;
  text-transform: capitalize;
}
.mc-invoice-recieved p,
.mc-invoice-shipment p {
  font-size: 14px;
}

.mc-invoice-shipment {
  text-align: right;
}
@media (max-width: 575px) {
  .mc-invoice-shipment {
    text-align: left;
  }
}

.mc-invoice-list-group {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--lightBorder);
}

.mc-invoice-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.mc-invoice-list li {
  display: flex;
}
.mc-invoice-list li:last-child {
  padding-top: 15px;
  border-top: 1px solid var(--lightBorder);
}
.mc-invoice-list li span {
  font-size: 15px;
}
.mc-invoice-list li .title {
  width: 100px;
  text-transform: capitalize;
}
.mc-invoice-list li .clone {
  font-weight: 700;
  margin-right: 30px;
}
.mc-invoice-list li .digit {
  font-weight: 500;
  color: var(--heading);
}
.mc-invoice-list li .total {
  font-size: 17px;
  font-weight: 700;
}
.mc-invoice-list li .status {
  padding: 3px 8px;
  border-radius: 6px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}
.mc-invoice-list li .status.purple {
  color: #be0ee1;
  background-color: #fbe5ff;
}
.mc-invoice-list li .status.green {
  color: #1a9f53;
  background-color: #ddfbe9;
}
.mc-invoice-list li .status.red {
  color: #f11133;
  background-color: #ffdfe4;
}

.mc-invoice-note {
  font-size: 13px;
  margin-bottom: 50px;
}

.mc-invoice-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}
.mc-invoice-btns .btn {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.mc-notify-item {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 15px;
  padding: 20px 10px;
  border-bottom: 1px solid var(--lightBorder);
}
.mc-notify-item:first-child {
  padding-top: 0px;
}
.mc-notify-item:last-child {
  border-bottom: none;
}

.mc-notify-content {
  display: flex;
  gap: 25px;
  color: var(--textColor);
}
.mc-notify-content:hover {
  color: var(--textColor);
}
@media (min-width: 0px) and (max-width: 450px) {
  .mc-notify-content {
    flex-wrap: wrap;
    gap: 15px;
  }
}

.mc-notify-media {
  position: relative;
}
.mc-notify-media img {
  width: 60px;
  border-radius: 50%;
}
.mc-notify-media i {
  width: 35px;
  height: 35px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid var(--lightBorder);
  color: var(--lightColor);
  font-size: 18px;
  position: absolute;
  top: 30px;
  right: -5px;
}
.mc-notify-media i.favorite {
  background: rgba(var(--redRGB), 75%);
}
.mc-notify-media i.rating {
  background: rgba(var(--yellowRGB), 75%);
}
.mc-notify-media i.comment {
  background: rgba(var(--greenRGB), 75%);
}
.mc-notify-media i.announce {
  background: rgba(var(--blueRGB), 75%);
}
.mc-notify-media i.blog {
  background: rgba(var(--purpleRGB), 75%);
}
.mc-notify-media i.privacy {
  background: rgba(var(--primaryRGB), 75%);
}
.mc-notify-media i.mail {
  background: rgba(var(--primaryRGB), 75%);
}

.mc-notify-meta {
  width: 100%;
  position: relative;
}
.mc-notify-meta span {
  display: block;
  margin-bottom: 5px;
}
.mc-notify-meta span b {
  font-weight: 500;
}
.mc-notify-meta small {
  display: block;
}

.mc-notify-close {
  margin-top: 5px;
  color: var(--textColor);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-notify-close:hover {
  color: var(--primaryHEX);
}

.mc-overview {
  background-color: #071739;
}

.mc-overview-banner {
  margin-bottom: 120px;
  padding: 150px 0px;
  position: relative;
  text-align: center;
  background-image: url("../images/pages-bg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
}
@media (max-width: 575px) {
  .mc-overview-banner {
    margin-bottom: 80px;
    padding: 80px 20px;
  }
}
.mc-overview-banner::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  background: linear-gradient(to bottom, rgba(7, 23, 57, 0.85), rgba(35, 81, 175, 0.85));
}
.mc-overview-banner .mc-logo-group {
  justify-content: center;
  flex-direction: column;
  margin: 0 auto 42px;
}
.mc-overview-banner .mc-logo-group img {
  height: 70px;
}
.mc-overview-banner .mc-logo-group span {
  background: -webkit-linear-gradient(#fbe5ff, #c1e1fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mc-overview-banner h1 {
  color: #dcedfd;
  margin-bottom: 10px;
  font-size: 66px;
  font-weight: 800;
  text-transform: capitalize;
}
@media (max-width: 575px) {
  .mc-overview-banner h1 {
    font-size: 45px;
  }
}
.mc-overview-banner p {
  color: #e4e4e4;
  font-size: 18px;
  max-width: 700px;
  margin: 0 auto 42px;
}
@media (max-width: 575px) {
  .mc-overview-banner p {
    font-size: 16px;
  }
}
.mc-overview-banner .tools {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .mc-overview-banner .tools {
    gap: 15px;
  }
}
.mc-overview-banner .tools h3 {
  white-space: nowrap;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  color: #d1d1d1;
}
@media (max-width: 575px) {
  .mc-overview-banner .tools h3 {
    font-size: 18px;
  }
}
.mc-overview-banner .tools img {
  width: auto;
  height: 40px;
}
@media (max-width: 575px) {
  .mc-overview-banner .tools img {
    height: 35px;
  }
}

.mc-overview-demo {
  padding: 0px 50px;
  margin-bottom: 120px;
}
@media (max-width: 767px) {
  .mc-overview-demo {
    padding: 0px;
  }
}
@media (max-width: 575px) {
  .mc-overview-demo {
    margin-bottom: 80px;
  }
}
.mc-overview-demo .col {
  padding: 0px 30px;
  margin-top: 50px;
}
@media (max-width: 767px) {
  .mc-overview-demo .col {
    padding: 0px 20px;
    margin-top: 40px;
  }
}

.mc-overview-title {
  text-align: center;
  margin-bottom: 35px;
  letter-spacing: -1px;
  text-transform: capitalize;
  font-size: 62px;
  font-weight: 800;
  background: -webkit-linear-gradient(#fbe5ff, #c1e1fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 575px) {
  .mc-overview-title {
    font-size: 44px;
  }
}

.mc-overview-footer {
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
  padding: 0px 20px 60px;
}
.mc-overview-footer h2 {
  color: #c1e1fc;
  text-transform: uppercase;
  font-size: 46px;
  font-weight: 900;
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .mc-overview-footer h2 {
    font-size: 32px;
  }
}
.mc-overview-footer a {
  margin-bottom: 100px;
}
@media (max-width: 575px) {
  .mc-overview-footer a {
    margin-bottom: 80px;
  }
}
.mc-overview-footer p span {
  color: var(--primaryHEX);
}

.mc-message-user {
  padding: 15px 0px;
}

.mc-message-user-filter {
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.mc-message-user-filter .mc-icon-field {
  margin-bottom: 0px;
  background-color: var(--thinGray);
}
.mc-message-user-filter .mc-dropdown-toggle i {
  width: 35px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
  border-radius: 8px;
  text-align: center;
  background-color: var(--lightGray);
}
.mc-message-user-filter .mc-dots-btn {
  padding: 7px 10px;
  gap: 10px;
}

.mc-message-user-list {
  overflow-y: auto;
  height: calc(100vh - 230px);
  padding: 5px 7px 5px 10px;
}
@media (max-width: 767px) {
  .mc-message-user-list {
    height: 400px;
  }
}

.mc-message-user-item {
  cursor: pointer;
  margin: 5px 0px;
  padding: 10px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-message-user-item:hover {
  background-color: var(--thinGray);
}
.mc-message-user-item.active {
  background-color: rgba(var(--primaryRGB), 10%);
}
.mc-message-user-item .mc-round-avatar {
  position: relative;
}
.mc-message-user-item .mc-round-avatar::before {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: auto;
  width: 13px;
  height: 13px;
  line-height: 13px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid var(--lightBorder);
  background-color: #9a9a9a;
  content: "";
}
.mc-message-user-item .mc-round-avatar.online::before {
  background-color: #27bf68;
}
.mc-message-user-item .mc-duel-text {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
}
.mc-message-user-item .mc-duel-text.unread .mc-duel-text-title {
  font-weight: 700;
}
.mc-message-user-item .mc-duel-text.unread .mc-duel-text-descrip {
  font-weight: 600;
  color: var(--activeColor);
}
.mc-message-user-item .mc-duel-text-title {
  overflow: hidden;
  text-overflow: ellipsis;
}
.mc-message-user-item .mc-duel-text-title small {
  font-weight: 400;
  color: var(--textColor);
  text-transform: capitalize;
}
.mc-message-user-item .mc-duel-text-title small::before {
  content: "~";
  margin: 0px 2px;
}
.mc-message-user-item .mc-duel-text-descrip {
  overflow: hidden;
  text-overflow: ellipsis;
}
.mc-message-user-item sup {
  border-radius: 15px;
  padding: 8px 4px 9px;
  font-size: 10px;
  font-weight: 500;
  color: var(--lightColor);
  background-color: var(--primaryHEX);
}
.mc-message-user-item .mc-dropdown-toggle i {
  width: 28px;
  height: 28px;
  font-size: 18px;
  line-height: 28px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--lightGray);
}

.mc-message-chat-header {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  border-bottom: 1px solid var(--lightBorder);
}
@media (max-width: 575px) {
  .mc-message-chat-header {
    justify-content: space-between;
  }
}
.mc-message-chat-header .mc-round-avatar {
  position: relative;
}
.mc-message-chat-header .mc-round-avatar::before {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: auto;
  width: 13px;
  height: 13px;
  line-height: 13px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid var(--lightBorder);
  background-color: #27bf68;
  content: "";
}
.mc-message-chat-header .mc-duel-text {
  flex-grow: 1;
}
@media (min-width: 0px) and (max-width: 400px) {
  .mc-message-chat-header .mc-duel-text {
    display: none;
  }
}
.mc-message-chat-header .mc-duel-text-title {
  font-weight: 600 !important;
}

.mc-message-chat-action-group {
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 12px;
}
.mc-message-chat-action-group i {
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  background-color: var(--lightGray);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-message-chat-action-group i:hover {
  color: var(--lightColor);
  background-color: var(--primaryHEX);
}

.mc-message-chat-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 15px 0px;
  overflow-y: auto;
  height: calc(100vh - 300px);
}
@media (max-width: 767px) {
  .mc-message-chat-list {
    height: 500px;
  }
}

.mc-message-chat-item {
  position: relative;
  display: flex;
  gap: 10px;
}
.mc-message-chat-item:hover .mc-message-chat-text i {
  transform: scale(1);
}
.mc-message-chat-item:nth-child(even) {
  flex-direction: row-reverse;
  text-align: right;
}
.mc-message-chat-item:nth-child(even) .mc-message-chat-text {
  flex-direction: row-reverse;
}
.mc-message-chat-item:nth-child(even) .mc-message-chat-text p {
  background-color: rgba(var(--primaryRGB), 15%);
}

.mc-message-chat-user {
  align-self: flex-end;
  border: none;
}
.mc-message-chat-user img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.mc-message-chat-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mc-message-chat-text {
  display: flex;
  align-items: center;
  gap: 10px;
}
.mc-message-chat-text p {
  max-width: 400px;
  font-size: 13px;
  padding: 7px 10px;
  border-radius: 15px;
  min-width: fit-content;
  background-color: var(--lightGray);
}
.mc-message-chat-text i {
  font-size: 18px;
  cursor: pointer;
  transform: scale(0);
  color: #b4b4b4;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.mc-message-chat-text i:hover {
  color: #818181;
}

.mc-message-chat-datetime {
  font-size: 12px;
}

.mc-message-chat-footer {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  border: 1px solid var(--deepBorder);
  background-color: var(--lightGray);
  border-radius: 30px;
  padding: 8px;
}
.mc-message-chat-footer input {
  width: 100%;
  color: var(--headingColor);
  padding-left: 7px;
}
.mc-message-chat-footer input::placeholder {
  font-size: 14px;
  padding: 10px;
}
.mc-message-chat-footer button {
  flex-shrink: 0;
  font-size: 15px;
  color: var(--lightColor);
  background-color: var(--primaryHEX);
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
}

.mc-setting-descrip {
  font-size: 15px;
}

.mc-color-group {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-template-rows: auto;
}

.mc-color div {
  width: 100%;
  height: 100px;
  border-radius: 8px;
}
.mc-color span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  font-size: 15px;
  padding: 5px;
}

.mc-blank {
  height: calc(100vh - 190px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-direction: column;
}
.mc-blank img {
  max-width: 300px;
}
.mc-blank i {
  font-size: 150px;
  line-height: 150px;
  background: -webkit-linear-gradient(#d1d1d1, #e4e4e4);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.mc-blank p {
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
  color: #9a9a9a;
}

.dark_mode .mc-blank i {
  background: -webkit-linear-gradient(#2f3f61, #1b2b4d);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.dark_mode .mc-blank p {
  color: #6a6a6a;
}

.mc-docs {
  padding: 30px 20px;
}
@media (max-width: 575px) {
  .mc-docs {
    padding: 15px 0px;
  }
}
.mc-docs h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: inherit;
}
@media (max-width: 767px) {
  .mc-docs h3 {
    white-space: inherit;
  }
}
.mc-docs b {
  font-weight: 600;
  color: var(--primaryHEX);
}
.mc-docs img {
  margin: 12px;
  height: 125px;
  border-radius: 8px;
  filter: invert(1);
}
@media (max-width: 767px) {
  .mc-docs img {
    width: 90%;
    height: auto;
  }
}

.mc-docs-footer {
  max-width: 300px;
  text-align: center;
  margin: 60px auto 50px;
}
@media (min-width: 0px) and (max-width: 350px) {
  .mc-docs-footer {
    max-width: 100%;
  }
}
.mc-docs-footer h3 {
  font-size: 36px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 28px;
}
@media (max-width: 575px) {
  .mc-docs-footer h3 {
    font-size: 28px;
  }
}

.mc-error {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  flex-direction: column;
  text-align: center;
  position: relative;
  padding: 50px 20px;
  z-index: 1;
}
.mc-error::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: url("../images/pattern.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.1;
}
.mc-error img {
  width: 100%;
  max-width: 550px;
  margin-bottom: 50px;
}
.mc-error h3 {
  font-size: 22px;
  margin-bottom: 3px;
  text-transform: uppercase;
}
.mc-error p {
  font-size: 14px;
  margin-bottom: 25px;
}

.colorLetra {
  color: var(--colorSetting) !important;
}

.form-control:disabled {
  background-color: var(--paperBG);
}

.form-control:disabled::placeholder {
  color: var(--colorSetting) !important;
}

.dateDark [type=date]::-webkit-calendar-picker-indicator {
  filter: invert(1) !important;
  border-radius: 50%;
  padding: 4px;
}

.date [type=date]::-webkit-calendar-picker-indicator {
  filter: invert(0) !important;
  border-radius: 50%;
  padding: 4px;
}

[type=date]::-webkit-datetime-edit {
  color: var(--colorSetting);
}

.image-flexit {
  width: auto;
  height: auto;
  max-width: 400px;
  max-height: 400px;
}

.mensaje-rojo-flexit {
  color: red;
}

.spinner-flexit {
  color: #27CCCA !important;
}

.color-date-flexit-dark[type=date]::-webkit-calendar-picker-indicator {
  filter: invert(1) !important;
}

.color-date-flexit[type=date]::-webkit-calendar-picker-indicator {
  filter: invert(0) !important;
}

.pointer {
  cursor: pointer !important;
}

.width-carrito {
  width: auto;
  min-width: 300px;
}

.tooltip-flexit {
  padding: 4px;
  background-color: var(--primaryGreen);
  color: var(--colorSetting);
  margin-bottom: 5px;
}

.width-auto-flexit {
  width: auto !important;
}
.width-auto-flexit button {
  background-color: var(--primaryGreen);
  color: var(--colorSetting);
}

.width-100-flexit {
  width: 100% !important;
}

.custom-input-group {
  font-size: 10px;
  padding: 0;
}
.custom-input-group .input-group-text {
  font-size: 10px;
  padding: 0.07rem 0.28rem;
}

.actualizar {
  color: var(--colorSetting) !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
  margin-right: 0.5em;
  display: inline-block;
  color: var(--colorSetting);
  content: "►";
}

.margin-text-vconsigna {
  margin-left: 10px !important;
}

.btn-group-flexit {
  height: 30px;
}

.btn-group-flexit span {
  font-size: 18px;
}

.btn-flexit-primary {
  background-color: #5a448b;
  color: white;
}

.btn-flexit-primary:hover {
  filter: opacity(0.8);
  background-color: #5a448b;
  color: white;
}

.btn-flexit-primary:focus {
  background-color: #5a448b;
  color: white;
}

.dtr-details li {
  text-align: start !important;
}

.dropdown-toggle::after {
  display: none;
}

.accordion-button::after {
  display: none;
}

.warning-custom-flexit {
  background: transparent;
  color: #856404;
  border: 1px solid transparent;
  transition: 0.5s all ease-in-out;
}

.warning-custom-flexit:hover {
  scale: 1.2;
  background: transparent;
  color: #856404;
  border: 1px solid transparent;
}

.warning-custom-flexit:focus {
  background: transparent;
  color: #856404;
  border: 1px solid #856404;
}

.success-custom-flexit {
  background: transparent;
  color: #155724;
  border: 1px solid transparent;
  transition: 0.5s all ease-in-out;
}

.success-custom-flexit:hover {
  scale: 1.2;
  background: transparent;
  color: #155724;
  border: 1px solid transparent;
}

.success-custom-flexit:focus {
  background: transparent;
  color: #155724;
  border: 1px solid #155724;
}

.rechazo-custom-flexit {
  background: transparent;
  color: #721c24;
  border: 1px solid transparent;
  transition: 0.5s all ease-in-out;
}

.rechazo-custom-flexit:hover {
  scale: 1.2;
  background: transparent;
  color: #721c24;
  border: 1px solid transparent;
}

.rechazo-custom-flexit:focus {
  background: transparent;
  color: #721c24;
  border: 1px solid #721c24;
}

.pendiente-custom-flexit {
  background: transparent;
  color: #383d41;
  border: 1px solid transparent;
  transition: 0.5s all ease-in-out;
}

.pendiente-custom-flexit:hover {
  scale: 1.2;
  background: transparent;
  color: #383d41;
  border: 1px solid transparent;
}

.pendiente-custom-flexit:focus {
  background: transparent;
  color: #383d41;
  border: 1px solid #383d41;
}

.cambiar-table tbody tr:hover td {
  color: var(--colorSetting);
}

.width-1-5 {
  width: 1.5em;
}

.dropdown-confirmar-rechazar {
  filter: opacity(0.8);
  background: var(--grayDropdown);
  color: var(--colorSetting);
  border: 1px solid var(--lightBorder);
  transition: 0.5s all ease-in-out;
}

.dropdown-confirmar-rechazar:hover {
  filter: opacity(1);
  background: var(--grayDropdown);
  color: var(--colorSetting);
  border: 1px solid var(--lightBorder);
}

.dropdown-confirmar-rechazar:hover {
  filter: opacity(1);
  background: var(--grayDropdown);
  color: var(--colorSetting);
  border: 1px solid var(--lightBorder);
}

.dropdown-confirmar-rechazar:focus {
  filter: opacity(1);
  background: var(--grayDropdown);
  color: var(--colorSetting);
  border: 1px solid var(--lightBorder);
}

.background-pen {
  background-color: #e2e3e5;
}

.background-pen a {
  background-color: #e2e3e5;
}

.background-pen a:hover {
  background-color: #b2c3e6;
}

.background-ama {
  background-color: #fff3cd;
}

.background-ama:hover {
  background-color: #fff3cd;
}

.background-ama:focus {
  background-color: #fff3cd;
}

.background-ama a {
  background-color: #fff3cd;
}

.background-ama a:hover {
  background-color: #f7e19c;
}

.background-ver {
  background-color: #d4edda;
}

.background-ver:hover {
  background-color: #d4edda;
}

.background-ver:focus {
  background-color: #d4edda;
}

.background-ver a {
  background-color: #d4edda;
}

.background-ver a:hover {
  background-color: #a6e7b5;
}

.background-roj {
  background-color: #f8d7da;
}

.background-roj:hover {
  background-color: #f8d7da;
}

.background-roj:focus {
  background-color: #f8d7da;
}

.background-roj a {
  background-color: #f8d7da;
}

.background-roj a:hover {
  background-color: #faa4ab;
}

.drop-confirmar-rechazar {
  background-color: var(--thinBorder);
}

.drop-confirmar-rechazar a {
  color: var(--colorSetting);
  background-color: var(--thinBorder);
}

.drop-confirmar-rechazar a:hover {
  color: var(--colorSetting);
  background-color: var(--lightBorder);
}

.centrar-row-span {
  vertical-align: middle;
  text-align: center;
}

#table_length label {
  color: var(--colorSetting);
}

#table_length label select {
  color: var(--colorSetting);
}

#table_length label::before select {
  color: var(--colorSetting);
}

.form-select {
  background-position: right;
}

#table_length label::after select {
  color: var(--colorSetting);
}

#table_filter label {
  color: var(--colorSetting);
}

#table_filter label input {
  color: var(--colorSetting);
}

.page-item.active .page-link {
  color: black !important;
  background: var(--primaryGreen) !important;
}

.pagination .page-link {
  position: relative;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  color: var(--colorSetting);
  text-decoration: none;
  background-color: var(--thinGray);
  border: 1px solid var(--hardBorder);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
  margin-right: 0.5em;
  display: inline-block;
  color: rgba(0, 0, 0, 0.5);
  content: "+";
  background-color: var(--primaryGreen);
  border-radius: 1em;
  box-sizing: content-box;
  width: 1em;
  font-size: 1.5em;
  color: var(--colorSetting) !important;
  border: 0.15em solid white;
  text-align: center;
  line-height: 1em;
}

table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th.dtr-control:before {
  content: "-";
  background-color: #d33333;
} */
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
  top: 20px;
  left: 5px;
  height: 1em;
  width: 1em;
  margin-top: -9px;
  display: block;
  position: absolute;
  color: white;
  border: 0.15em solid white;
  border-radius: 1em;
  box-shadow: 0 0 0.2em #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New", Courier, monospace;
  line-height: 1em;
  content: "+";
  background-color: var(--primaryGreen);
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th.dtr-control:before {
  content: "-";
  background-color: #d33333;
}

.texto-100 {
  display: block !important;
  width: 100% !important;
}

.custom-accordion {
  background: red;
  color: white;
}

@media (min-width: 1500px) {
  #table thead tr th:nth-child(2),
  #table tbody tr td:nth-child(2) {
    width: auto !important;
    max-width: auto !important;
  }
}
@media (max-width: 1200px) {
  #table thead tr th:nth-child(2),
  #table tbody tr td:nth-child(2) {
    width: 200px !important;
    max-width: 200px !important;
  }
}
.input-group-flexit {
  background: transparent;
  color: var(--colorSetting);
  border: 1px solid var(--lightBorder);
}

.text-bg-flexit {
  color: #fff !important;
  background-color: #120144 !important;
}

.height-100 {
  height: 100% !important;
}

.botones-offcanvas button {
  margin: 0px 5px;
}

.alto-creacion {
  height: 480px;
  overflow: auto;
}

.eureka-bg-success {
  background-color: #dff0d8 !important;
}

.eureka-bg-danger {
  background-color: #f2dede !important;
}

.eureka-bg-info {
  background-color: #d9edf7 !important;
}

.eureka-bg-warning {
  background-color: #fcf8e3 !important;
}

.eureka-bg-primary {
  background-color: #337ab7 !important;
}

.button-conector {
  position: relative;
  padding: 0.25rem 1rem 0.25rem 0.5rem !important;
}

.button-conector::before {
  content: "▼";
  position: absolute;
  color: var(--colorSetting);
  top: 3px;
  right: 2px;
  pointer-events: none;
}

.button-conector:hover::before {
  content: "▼";
  position: absolute;
  color: var(--colorSetting);
  filter: invert(100%);
  top: 3px;
  right: 2px;
  pointer-events: none;
}

.f-13 {
  font-size: 13px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

.dataTables_wrapper .dataTables_filter {
  float: none !important;
  text-align: left;
}

.dataTables_wrapper .dataTables_filter label input {
  width: 100% !important;
}

