@import "../1-helpers/colors";

.tablePrincipal {
  position: relative;
}

.botonAbrir {
  width: 90px;
  cursor: pointer;
}

button.dt-button,
div.dt-button,
a.dt-button,
input.dt-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin-left: .167em;
  margin-right: .167em;
  margin-bottom: .333em;
  padding: .1em .4em;
  border: 1px solid var(--primaryGreen);
  border-radius: 2px;
  cursor: pointer;
  font-size: .88em;
  line-height: 1.6em;
  white-space: nowrap;
  overflow: hidden;
  background: var(--primaryGreen);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  outline: none;
  text-overflow: ellipsis;
}

button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled),
input.dt-button:hover:not(.disabled) {
  filter: opacity(.8);
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin-left: .167em;
  margin-right: .167em;
  margin-bottom: .333em;
  padding: .2em .8em;
  border: 1px solid var(--primaryGreen);
  border-radius: 2px;
  cursor: pointer;
  font-size: .88em;
  line-height: 1.6em;
  white-space: nowrap;
  overflow: hidden;
  background: var(--primaryGreen);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  outline: none;
  text-overflow: ellipsis;
}

/* button.dt-button:hover,
div.dt-button:hover,
a.dt-button:hover,
input.dt-button:hover {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin-left: .167em;
  margin-right: .167em;
  margin-bottom: .333em;
  padding: .2em .8em;
  border: 1px solid var(--primaryGreen);
  border-radius: 2px;
  cursor: pointer;
  font-size: .88em;
  line-height: 1.6em;
  white-space: nowrap;
  overflow: hidden;
  background: var(--primaryGreen);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  outline: none;
  text-overflow: ellipsis;
} */

.modalBackground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.th {
  font-size: 12px;
}

.th::first-letter {
  text-transform: uppercase;
}

table {

  thead {
    .width-opciones {
      width: 60px !important;
    }

    tr {
      background-color: var(--primaryGreen);

      th {
        color: var(--colorSetting);
      }
    }
  }

  tbody {

    tr .child {
      color: var(--colorSetting);
    }

    tr:hover {
      color: var(--colorSetting);
    }
  }

  .width-opciones {
    width: 60px !important;
  }

  .td {
    color: var(--colorSetting);

    span {
      &::after {
        display: none;
      }

      button {
        color: var(--primaryGreen);
        padding: 2px;
        transition: .5s all ease-in-out;
      }

      button:hover {
        transform: scale(1.2);
      }

      button:focus {
        transform: scale(1.2);
      }
    }
  }
}

#table {

  .td {
    white-space: inherit;
  }
}

.modalTabla {
  position: absolute;
  left: 30%;
  top: 30%;
  width: 400px;
  min-height: 200px;
  overflow: auto;
  background-color: var(--bodyBG);
  border: 1px solid $white-m;
  padding: 10px;

  h6 {
    padding-bottom: 5px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.contenedorSet {
  background-color: var(--primaryGreen);
  padding: 2px 5px;
  border-radius: 5px;

  & i {
    color: var(--colorSetting);
  }
}

.dropdown-toggle {
  color: var(--colorSetting);
}

.cerrarModal {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  color: #fff;
  background-color: $blue-m;
  border-radius: 10px;
  text-align: center;
}

.tituloTabla {
  margin-bottom: 20px;
}

#table_length label {
  font-size: 12px;
}

#table_filter label {
  font-size: 12px;
}

#table_filter label input {
  font-size: 12px;
}

#table_info {
  font-size: 12px;
}

#table_paginate {
  font-size: 12px;
}