.horizontal {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .row-tablero {
        min-width: 310px;
        width: auto;
    }
}

.tooltip-flexit {
    padding: 4px;
    background-color: var(--primaryGreen);
    color: var(--colorSetting);
    margin-bottom: 5px;
}

.containerAccordion1 {
    display: flex;
    background-color: var(--cardBG);
    border: 1px solid var(--lightBorder);
    justify-content: space-between;
    flex-wrap: wrap;
    height: 200px;
    overflow: hidden;
}

.containerAccordionTotal1 {
    display: flex;
    background-color: var(--cardBG);
    border: 1px solid var(--lightBorder);
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
}

.containerAccordion2 {
    display: flex;
    background-color: var(--cardBG);
    border: 1px solid var(--lightBorder);
    justify-content: space-between;
    flex-wrap: wrap;
    height: 200px;
    overflow: hidden;
}

.containerAccordionTotal2 {
    display: flex;
    background-color: var(--cardBG);
    border: 1px solid var(--lightBorder);
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
}

.containerAccordion3 {
    display: flex;
    background-color: var(--cardBG);
    border: 1px solid var(--lightBorder);
    justify-content: space-between;
    flex-wrap: wrap;
    height: 200px;
    overflow: hidden;
}

.containerAccordionTotal3 {
    display: flex;
    background-color: var(--cardBG);
    border: 1px solid var(--lightBorder);
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
}

.container .container-fluid .text-center {
    .row {
        .formControl {
            color: var(--colorSetting);
            width: 180px;
            margin-left: 5px;
        }
    }
}

.containerPendiente {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .flex {
        display: flex;
        flex-wrap: wrap;
    }

}

.sinOrdenes {
    margin-top: 5px;
    width: 100%;
    height: 100%;
    background-color: var(--cardBG);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}